import * as React from "react";
// Customizable Area Start
import { withStyles, Box, Typography, Grid, ThemeProvider, createTheme, Button, Dialog } from "@material-ui/core";
import PatientOrderManagementController, { Props } from "./PatientOrderManagementController";
import Sidebar from "../../../components/src/Sidebar.web";
import { ProfileIcon } from "./assets";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});
// Customizable Area End

export class UserProfile extends PatientOrderManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
      this.fetchHospitalData()
    }

    showPopup = (classes: any, responeMsg: string, type: string) => {
        return (
            <Box>
                <Dialog open={!!responeMsg}>
                    <Box className={classes.popupBox}>
                        <Box style={{ padding: "0px 10px" }}>
                            <p className={classes.popupHeading}>{type}</p>
                            <p className={classes.popupMessage}>{responeMsg}</p>
                            <Button className={classes.popupBtn} data-test-id="closePopup" onClick={this.closePopup}>OK</Button>
                        </Box>
                    </Box>
                </Dialog>
            </Box>
        )
    } 
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { hospitalList } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    <Box className={classes.leftWrapper}>
                        <SidebarNotificationWrapper {...this.props}>
                            <Box className={classes.rightWrapper}>
                                <Box className={classes.root}>
                                <Box className={classes.headerContainer}>
                                        <Box className={classes.orderHeading}>
                                            <ArrowBackIosIcon
                                                className={classes.calendarBackBtn}
                                                data-test-id="calendarBackBtn"
                                                onClick={this.backToOrderManagementScreen}
                                            />
                                            <Typography variant="h4" className={classes.profileHeading}>View Details</Typography>
                                        </Box>
                                    </Box>
                                    <Grid container>
                                        <Grid item xl={8} md={12} sm={12} xs={12}>
                                            <Box className={classes.topHPDetailsLeftContainer}>
                                                <Box className={classes.profileContainer}>
                                                    <img
                                                        className={classes.avatar}
                                                        src={hospitalList?.profile_image === null ? ProfileIcon : hospitalList?.profile_image}
                                                    />
                                                </Box>
                                                <Box className={classes.profileDetailsContainer}>
                                                    <Typography variant="h6" className={classes.userName}>
                                                        {hospitalList?.name}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xl={8} md={12} sm={12} xs={12}>
                                        </Grid>
                                        <Grid item xl={6} md={12} sm={12} xs={12}>
                                            <Box>
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsHPLabel}>
                                                        Address
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails}>
                                                    {hospitalList?.address?.city}
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsHPLabel}>
                                                        Bed Booked
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails}>
                                                        {hospitalList?.bed_booked}
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsHPLabel}>
                                                        Service Booked
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails}>
                                                        {hospitalList?.service_booked}
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsHPLabel}>
                                                        Date
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails}>
                                                    {hospitalList?.date}
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsHPLabel}>
                                                        Fees
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails}>
                                                        Rs. {hospitalList?.amount}.00
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box className={classes.btnContainer}>
                                <Button className={classes.prescriptionBooking} data-test-id="viewPrescription" onClick={() => this.moveHospitalToPrescriptionScreen(this.props.navigation.getParam("navigationBarTitleText"))}>
                                    Prescription
                                </Button>
                                <Button className={classes.cancelBooking} data-test-id="cancelPrescription" onClick={this.cancelHospitalBooking}>
                                    Cancel Booking
                                </Button>
                                </Box>
                            </Box>
                            {this.showPopup(classes,this.state.message,this.state.messageType)}
                        </SidebarNotificationWrapper>
                    </Box>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    leftWrapper: {
        height: "100%",
        overflowX: "hidden" as "hidden"
    },
    rightWrapper: {
        height: "100%",
        overflowY: "scroll" as "scroll",
        scrollbarColor:"#d9d5d5 #fff",
        scrollbarWidth: "thin" as "thin",
        "& ::-webkit-scrollbar-button": {
            display: "none"
        },
        padding:"50px"
    },
    profileHeading: {
        width: "100%",
        fontSize: "28px",
        fontFamily: "SF Pro Text Bold",
        color: "#292929",
        caretColor: "transparent"
      },
    topHPDetailsLeftContainer: {
        display: "flex",
        fontFamily: "SF Pro Text Regular",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 0px 35px 0px",
        maxWidth:"800px"
    },
    profileContainer: {
        width: "15%",
        [theme.breakpoints.down("md")]: {
          width: "40%"
        },
        [theme.breakpoints.down("sm")]: {
          width: "50%"
        }
    },
    avatar: {
        borderRadius: "50%",
        width: "155px",
        height: "155px",
        border: "1px solid #eeeaea",
        backgroundColor: "#eeeaea"
    },
    profileDetailsContainer: {
        width: "75%",
        [theme.breakpoints.down("xl")]: {
          paddingLeft: "30px"
        },
        [theme.breakpoints.down("md")]: {
          width: "70%",
          paddingLeft: "10px"
        },
        [theme.breakpoints.down("sm")]: {
          width: "50%",
          paddingLeft: "0px"
        }
    },
    userName: {
        color: "#292929",
        fontSize: "24px",
        fontFamily: "SF Pro text Bold",
        caretColor: "transparent"
    },  
    subContainer: {
        display: "flex",
        fontFamily: "SF Pro Text Regular",
        justifyContent: "space-between",
        alignItems: "start",
        padding: "22px 0px 0px",
        width: "100%",
        maxWidth:"800px"
    },
    userDetailsHPLabel: {
        fontSize: "18px",
        fontFamily: "SF Pro Text Medium",
        width: "45%",
        "@media (max-width:1300px)": {
            width: "50% !important"
        },
        caretColor: "transparent"
    },
    userDetailsColon: {
        caretColor: "transparent",
        fontSize: "18px",
        fontFamily: "SF Pro Text Medium",
        color: "#808080",
        width: "8%"
    },
    userDetails: {
        caretColor: "transparent",
        fontSize: "18px",
        fontFamily: "SF Pro Text Regular",
        color: "#717171",
        width: "75%",
        paddingRight: "217px",
        textTransform: "capitalize" as "capitalize",
    },
    headerContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    orderHeading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "25px",
        marginBottom:"40px"
    },
    calendarBackBtn: {
        display: "flex",
        alignItems: "center",
        fontSize: "22px",
        justifyContent: "center",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
        padding: "13px 9px 13px 17px",
    },
    cancelBooking: {
        border: "none",
        color: "#FFFFFF",
        width:"200px",
        height:"56px",
        alignItem:"center" as "center",
        alignContent:"center" as "center",
        marginBottom: "15px",
        borderRadius: "8px",
        caretColor: "transparent",
        backgroundColor: "#014866",
        fontSize:"18px",
        fontFamily:"SF PRO Text Bold",
        "&.MuiButton-root:hover": {
            backgroundColor: "#014866",
            color: "#FFFFFF",
        },
        textTransform: "capitalize" as "capitalize",
        cursor: "pointer",
    },
    prescriptionBooking: {
        border: "1px solid #014866",
        color: "#014866",
        width:"200px",
        height:"56px",
        alignItem:"center" as "center",
        alignContent:"center" as "center",
        marginBottom: "15px",
        borderRadius: "8px",
        caretColor: "transparent",
        backgroundColor: "#FFFFFF",
        fontSize:"18px",
        fontFamily:"SF PRO Text Bold",
        "&.MuiButton-root:hover": {
            backgroundColor: "#FFFFFF",
            color: "#014866",
        },
        cursor: "pointer",
        textTransform: "capitalize" as "capitalize"
    },
    btnContainer: {
        display: "flex",
        gap: "25px",
        marginTop: "40px"
    },
    popupBox: {
        display: "block",
        maxWidth: "550px",
        width: "500px",
        height: "auto",
        textAlign: "left" as "left",
        borderRadius: "5px"
      },
    popupHeading: {
        width: "100%",
        color: "black",
        fontFamily: "SF Pro Text Regular",
        fontSize: "28px",
        fontWeight: 700,
        height: "8px",
        marginTop: "15px",
        caretColor: "white"
      },
    popupmessage: {
        width: "100%",
        color: "black",
        fontFamily: "SF Pro Text Regular",
        fontSize: "16px",
        height: "8px",
        margin: "30px 0px",
        caretColor: "white"
      },
      popupBtn:{
        borderRadius: "3px",
        backgroundColor: "rgb(33, 150, 243)",
        border: "none",
        color: "#fff",
        padding: "5px",
        marginBottom: "15px",
        marginTop: "10px",
        fontFamily: "SF Pro Text Regular"
    },
}

export default withStyles(styles)(UserProfile);
// Customizable Area End