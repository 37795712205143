import * as React from "react";
// Customizable Area Start
import { Box, Grid, Typography, withStyles, Button } from "@material-ui/core";
import HpOrderManagementController, { Props } from "../HpOrderManagementController";
import Sidebar from "../../../../components/src/Sidebar.web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {ProfileIcon} from "../assets";
// Customizable Area End

export class OrderManagementPrescription extends HpOrderManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getPrescription()
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {classes} = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
        <>
            <Box>
                <Grid container className={classes.leftHPWrapper}>
                    <Grid item xs={1}>
                        <Sidebar />
                    </Grid>
                    <Grid item xs={11} className={classes.righHPtWrapper}>
                        <Box>
                            <Box className={classes.headerHPContainer}>
                                <Box className={classes.orderHeading}>
                                    <ArrowBackIosIcon
                                        className={classes.calendarHPBackBtn}
                                        data-test-id="backToPreviousScreenfromPrecription"
                                        onClick={() => this.backToPrevPage()}
                                    />
                                    <Typography variant="h4" className={classes.profileHPHeading}>Prescriptions</Typography>
                                </Box>
                            </Box>
                            {this.state.prescriptions !== null && this.state.prescriptions.length > 0 ? (
                                this.state.prescriptions.map((data: any, index: number) => (
                                    <Box className={classes.docList} key={index}>
                                        <Box className={classes.container}>
                                            <Box className={classes.blockOne}>
                                                <img
                                                    alt="Profile"
                                                    className={classes.avatar}
                                                    src={data.attributes.signature_path.url !== null ? data.attributes.signature_path?.url: ProfileIcon}
                                                />
                                            </Box>
                                            <Box className={classes.blockTwo}>
                                                <Typography variant="subtitle1" className={classes.userName}>
                                                    {data.attributes.healthcare_personnel_name}
                                                </Typography>
                                            </Box>
                                            <Box className={classes.blockThree}>
                                                <Typography variant="subtitle1" className={classes.day}>
                                                    {this.getDayOfWeek(data.attributes.date)}
                                                </Typography>
                                                <Typography variant="caption" className={classes.time}>
                                                    {data.attributes.time}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Button
                                            data-test-id="openPrescriptionPrevirew"
                                            variant="outlined"
                                            className={classes.button}
                                            onClick={() => this.callPrescription(data)}
                                        >
                                            Open File
                                        </Button>
                                    </Box>
                                ))
                            ) : (
                                <Typography variant="body1" className={classes.noData}>
                                    No prescriptions available.
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
    leftHPWrapper: {
        overflowX: "hidden" as "hidden",
        height: "100%",
    },
    righHPtWrapper: {
        height: "100%",
        padding:"50px",
        overflowY: "scroll" as "scroll",
        scrollbarColor: "#fff #fff",
        scrollbarWidth: "thin" as "thin",
        "& ::-webkit-scrollbar-button": {
            display: "none"
        },
    },
    headerHPContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    orderHeading: {
        display: "flex",
        alignItems: "center",
        marginBottom:"40px",
        justifyContent: "flex-start",
        gap: "25px",
    },
    calendarHPBackBtn: {
        display: "flex",
        alignItems: "center",
        fontSize: "22px",
        padding: "13px 9px 13px 17px",
        justifyContent: "center",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
    },
    profileHPHeading: {
        width: "100%",
        caretColor: "transparent",
        fontSize: "28px",
        fontFamily: "SF Pro Text Bold",
        color: "#292929",
    },
    docList:{
        marginTop: "12px",
        borderBottom: "1px solid #e8e7e7",
        borderRadius: "10px",
        padding: "20px",
        maxWidth: "600px",
        height: "135px",
        marginBottom: "10px"
    },
    container: {
        display: "flex",
    },
    blockOne: {
        width: "20%",
        display: "block",
        margin: "auto"
    },
    blockTwo: {
        width: "40%",
        display: "block",
        margin: "auto"
    },
    blockThree: {
        width: "40%",
        display: "block",
        float: "right",
        textAlign: "right",
        margin: "auto 0px auto 13px"
    } as any,
    userName: {
        fontWeight: 600,
        fontSize: "16px",
        caretColor: "transparent",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    },
    status: {
        caretColor: "transparent",
        fontSize: "12px",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        color: "gray"
    },
    day: {
        fontWeight: 600,
        fontSize: "12px",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        color: "#014866",
        caretColor: "transparent",
    },
    time: {
        caretColor: "transparent",
        fontSize: "12px",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        color: "gray"
    },
    date: {
        width: "75%",
        display: "flex",
        justifyContent: "center",
        fontWeight: 600,
        fontSize: "15px",
        caretColor: "transparent",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        color: "#e3e3e3"
    },
    button: {
        color: "#2196f3",
        border: "2px solid #2196f3",
        margin: "8px 115px 30px",
        padding: "8px 30px",
        borderRadius: "8px",
        textTransform: "capitalize" as "capitalize",
        fontWeight: 600,
        fontSize: "14px"
    },
    avatar: {
        width: "80px",
        height: "80px",
        borderRadius: "50%"
    },
}

export default withStyles(styles)(OrderManagementPrescription);
// Customizable Area End