import React from 'react'
import CommoncontactusController from "./CommoncontactusController";
import { Box, Button, Dialog, FormHelperText, Grid, TextField, withStyles, Typography,  } from '@material-ui/core';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

export class CommonContactus extends CommoncontactusController {

    errorMessage = (error: string | undefined, inputError: any) => {
        return error ? (
            <FormHelperText className={inputError}>{error}</FormHelperText>
        ) : null;
    };

    showErrorPopup = (classes: any, message: string, type: string) => {
        return (
            <Box>
                <Dialog open={!!message}>
                    <Box className={classes.popupContainer}>
                        <Box style={{ padding: "0px 10px" }}>
                            <p className={classes.textHeading}>{type}</p>
                            <p className={classes.message}>{message}</p>
                            <Button className={classes.btnStyle} 
                                onClick={this.closeMessagePopup}
                            >OK</Button>
                        </Box>
                    </Box>
                </Dialog>
            </Box>
        )
    } 

    
    render() {
        const { formValues, formErrors, message,messageType } = this.state;
        const { classes } = this.props;
        return (
            <Box sx={webStyle.container}>
                <Box sx={webStyle.rightWrapper}>
                    <Box sx={webStyle.pageTitleWrapper}>
                        <Box sx={webStyle.hospitalEditBackButtons} data-test-id="moveToNextScreen" onClick={this.moveToNextScreen}>
                            <ArrowBackIosIcon style={webStyle.arrowIcon} />
                        </Box>
                        <Typography style={webStyle.profileHospitalHeading} variant="h6">
                            Help Center
                        </Typography>
                    </Box>
                    <Box sx={webStyle.contentWrapper}>
                        <Typography style={webStyle.headerText}>HomeCure</Typography>
                        <Typography style={webStyle.subHeaderText}>
                            You can contact us on toll free number or on Email!
                        </Typography>
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    data-test-id="phone"
                                    placeholder="Enter your phone number"
                                    value={formValues.phone}
                                    onChange={(e) => this.handleChange("phone", e.target.value)}
                                    className={Boolean(formErrors.phone) ? classes.errorRegisterInputFields : classes.registerInputFields}
                                />
                                {this.errorMessage(formErrors.phone, classes.inputErrors)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    placeholder="Enter your mail"
                                    data-test-id="email"
                                    variant="outlined"
                                    fullWidth
                                    value={formValues.email}
                                    onChange={(e) => this.handleChange("email", e.target.value)}
                                    className={Boolean(formErrors.email) ? classes.errorRegisterInputFields : classes.registerInputFields}
                                />
                                {this.errorMessage(formErrors.email, classes.inputErrors)}
                            </Grid>
                        </Grid>
                        <Typography style={webStyle.contactUsText}>Contact Us</Typography>
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={12} md={6} style={{paddingBottom:"8px"}}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    data-test-id="name"
                                    placeholder="Enter your name"
                                    value={formValues.user_name}
                                    onChange={(e) => this.handleChange("user_name", e.target.value)}
                                    className={Boolean(formErrors.user_name) ? classes.errorRegisterInputFields : classes.registerInputFields}
                                />
                                {this.errorMessage(formErrors.user_name, classes.inputErrors)}
                            </Grid>
                            <Grid item xs={12} sm={12} style={{paddingTop:"8px"}}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    data-test-id="message"
                                    placeholder="Write your message..."
                                    multiline
                                    minRows={4}
                                    value={formValues.message}
                                    onChange={(e) => this.handleChange("message", e.target.value)}
                                    className={Boolean(formErrors.message) ? classes.errorRegisterInputFields : classes.registerInputFields}
                                />
                                {this.errorMessage(formErrors.message, classes.inputErrors)}
                            </Grid>
                        </Grid>
                        <Button className={classes.contactSubmitBtn} data-test-id="submit" onClick={this.handleSubmit}>Submit</Button>
                    </Box>
                    {this.showErrorPopup(classes,message,messageType)}
                </Box>
            </Box>
        );
    }
}


const webStyle = {
    sidebarWrapper: {
        width: '132px',
        minHeight: 'max(100vh, 700px)',
    },
    container: {
        height: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: "100vh",
        overflowX: "hidden" as 'hidden',
    },
    rightWrapper: {
        minHeight: '100vh',
        width: '100%',
    },
    contentWrapper: {
        padding: '0px 60px 72px 60px',
        maxWidth: '55%',
        "@media (max-width:769px)": {
            padding: '0px 30px',
            maxWidth: '100%',
            margin:"auto",
        },
    },
    subHeaderText: {
        color: '#292929',
        fontSize: '22px',
        fontFamily: 'SF Pro Text Semibold',
        marginBottom: '20px',
    },
    contactInfoWrapper: {
        marginBottom: '40px',
    },
    contactUsText: {
        fontFamily: 'SF Pro Text Semibold',
        fontSize: '20px',
        color: '#292929',
        marginBottom: '20px',
        marginTop: '15px',
    },
    messageField: {
        marginBottom: '90px',
        marginTop: '20px',
    },
    modalDialog: {
        borderRadius: '25px',
        backgroundColor: '#F2F1FA',
        maxWidth: '450px',
        width: '100%',
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
    },
    modalImage: {
        marginBottom: '30px',
    },
    modalText: {
        color: '#292929',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Bold',
        marginBottom: '50px',
    },
    modalButton: {
        width: '100%',
    },
    pageTitleWrapper: {
        display: 'flex',
        alignItems: 'center' as 'center',
        height: '140px',
        paddingLeft: '60px',
        gridColumnGap: '30px',
        "@media (max-width:769px)": {
            padding: '0px 30px',
        },
    },
    hospitalEditBackButtons: {
        display: "flex",
        alignItems: 'center' as 'center',
        width: "50px",
        height: "50px",
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        border: "1px solid #E9E9E9",
        padding: "10px",
        justifyContent: "center" as 'center',
        borderRadius: "8px",
        boxSizing: 'border-box' as 'border-box',
    },
    arrowIcon: {
        height: "16px",
        caretColor: "white",
    },
    profileHospitalHeading: {
        width: "100%",
        fontWeight: 600,
        fontSize: "28px",
        fontFamily: "SF Pro Text Bold",
        caretColor: "transparent"
    },
    registerInputFields: {
        width: "100%",
        fontSize: "14px",
        marginBottom: "10px",
        borderRadius: "8px",
        "& .MuiInputBase-root": {
            color: "#292929",
            fontSize: "14px",
            fontFamily: "SF Pro Text Regular",
            background: "white !important",
            borderRadius: "10px",
            border: "none"
        },
        "& input": {
            fontSize: "14px",
            color: "#292929",
            fontFamily: "SF Pro Text Regular",
            "&::placeholder": {
                opacity: 1,
                color: "#808080 !important",
                fontSize: "14px",
                fontFamily: "SF Pro Text Regular",
            }
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: "8px"
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px",
            borderColor: "#3f51b5 !important"
        },
        fontFamily: "SF Pro Text Regular"
    },
    errorRegisterInputFields: {
        width: "100%",
        fontSize: "14px",
        marginBottom: "10px",
        borderRadius: "8px",
        "& .MuiInputBase-root": {
            color: "#292929",
            fontSize: "14px",
            fontFamily: "SF Pro Text Regular",
            background: "white !important",
            borderRadius: "10px"
        },
        "& input": {
            fontSize: "14px",
            color: "#292929",
            fontFamily: "SF Pro Text Regular",
            "&::placeholder": {
                opacity: 1,
                color: "#808080 !important",
                fontSize: "14px",
                fontFamily: "SF Pro Text Regular",
            }
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px",
            borderColor: "#3f51b5 !important"
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: "8px"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FF0000",
            borderWidth: "1px"
        }
    },
    headerText: {
        color: '#014866',
        fontFamily: 'SF Pro Text Heavy',
        fontSize: '30px',
        lineHeight: '35px',
        marginBottom: '32px',
    },
    inputErrors: {
        display: "flex",
        justifyContent: "end",
        color: "#FF0000",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
        caretColor: "transparent"
    },
    popupContainer: {
        display: "block",
        maxWidth: "550px",
        width: "500px",
        height: "auto",
        textAlign: "left" as "left",
        borderRadius: "5px"
      },
    textHeading: {
        width: "100%",
        color: "black",
        fontFamily: "SF Pro Text Regular",
        fontSize: "28px",
        fontWeight: 700,
        height: "8px",
        marginTop: "15px",
        caretColor: "white"
      },
    message: {
        width: "100%",
        color: "black",
        fontFamily: "SF Pro Text Regular",
        fontSize: "16px",
        height: "8px",
        margin: "35px 0px",
        caretColor: "white"
      },
      btnStyle:{
        borderRadius: "3px",
        backgroundColor: "rgb(33, 150, 243)",
        border: "none",
        color: "#fff",
        padding: "5px",
        marginBottom: "15px",
        marginTop: "10px",
        fontFamily: "SF Pro Text Regular"
    },
    contactSubmitBtn: {
        marginTop:"25px",
        fontSize: '18px',
        borderRadius: '8px',
        fontFamily: 'SF Pro Text Bold',
        width: "200px",
        height: "56px",
        textTransform: 'capitalize' as 'capitalize',
        backgroundColor: '#014866',
        color: '#fff',
        "&:hover": {
          backgroundColor: "#014866",
          color: "#fff"
        },
    },
};


export default withStyles(webStyle)(CommonContactus);