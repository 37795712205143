import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Hidden,
  Drawer,
  IconButton,
  Avatar,
  Divider,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { completed, homecure, offer, rejected, sos } from "./assets";
import Sidebar from "../../../components/src/Sidebar.web";
import CustomStyledButton from "../../../components/src/CustomStyledButton.web";
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import parse, { domToReact, HTMLReactParserOptions, Element as DOMElement } from 'html-react-parser';
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import CircularProgress from '@material-ui/core/CircularProgress';

interface IModalMeta {
    modalTitle: React.CSSProperties;
    modalStyle: React.CSSProperties;
    modalHeader: React.CSSProperties;
    title: string;
    onClose: () => void
}

enum ModalType {
    HEALTH_DETAILS = 'health-details',
    INSURANCE_MODAL = 'insurance-modal',
  }
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";
import CommonModal from "../../../components/src/CommonModal.web";
import Loader from "../../../components/src/Loader.web";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNotificationIcon = (type: string|null) => {
    switch (type) {
        case 'sos': return sos;
        case 'success': return completed;
        case 'reject': return rejected;
        case 'offer': return offer;
        default: return ''
    }
}

    parseOptions: HTMLReactParserOptions = {
        replace: (domnode: unknown) => {
            if (domnode instanceof DOMElement) {
                if (domnode.name === 'p') {
                    return (
                        <Typography style={webStyle.mainContent} >
                            {domToReact(domnode.children, this.parseOptions)}
                        </Typography>
                    );
                }
                else if (domnode.name === 'b') {
                    return <span style={webStyle.notificationBold} >
                        {domToReact(domnode.children)}
                    </span>
                }
            }
        }
    };

    renderNotification = () => {
        return (
            <>
                {!this.props.hideNotification && <div ref={this.scrollContainerRef} onScroll={this.onScroll} data-test-id='notification-menu' style={webStyle.notificationWrapper}>
                    <Box sx={webStyle.notification}>
                        <Avatar style={webStyle.avatar} src={this.state.profileImage} />
                        <Typography style={webStyle.userName}>{this.state.profileName}</Typography>
                        <Typography style={webStyle.userPhone}>+91 {this.state.phoneNumber}</Typography>
                    </Box>
                    <Box sx={webStyle.notificationSection}>
                        {Object.entries(this.state.notificationList).length > 0 && Object.entries(this.state.notificationList).map(([key, valuesArr], indx) => (
                            <Box sx={webStyle.notificationCategory} key={key}>
                                <Typography style={indx === 0 ? webStyle.categoryTitle : { ...webStyle.categoryTitle, color: 'rgba(0, 0, 0, 0.30)' }}>{key}</Typography>
                                {valuesArr.map((value, index) => (
                                    <Box sx={webStyle.notificationItem} key={index}>
                                        <Avatar src={this.renderNotificationIcon(value.attributes.notification_type)} />
                                        <Box sx={webStyle.notificationContent}>
                                            <Typography style={webStyle.mainContent}>{parse(value.attributes.headings, this.parseOptions)}</Typography>
                                            <Typography style={webStyle.time}>{this.timeSince(value.attributes.created_at)} ago</Typography>
                                            {this.state.userType === 'hospital' && value.attributes.notification_type !==null &&
                                                <Typography style={webStyle.links}>
                                                    <span data-test-id='insurance-list' onClick={() => this.getInsuranceList(value.attributes.notificable_id)}> View Insurance </span>
                                                </Typography>
                                            }
                                            {value.type === 'sos' && (
                                                <Box sx={webStyle.buttonsWrapper}>
                                                    <CustomStyledButton
                                                        style={webStyle.button}
                                                        isSecondary
                                                        label="Decline"
                                                    />
                                                    <CustomStyledButton style={webStyle.button} label="Accept" />
                                                </Box>
                                            )}
                                            <Divider style={webStyle.divider} />
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        ))}
                    </Box>
                    {this.state.isNotificationLoading && <Box style={webStyle.notificationLoader} >   <CircularProgress style={{ color: '#014866' }} /></Box>}
                </div>}
            </>
        );
    };

    renderTopBar = () => {
        return this.state.isMobileScreen && (
            <Box style={webStyle.topBar}>
                <Hidden mdUp implementation="css">
                    <IconButton
                        style={webStyle.iconButton}
                        data-test-id="menuBtn"
                        onClick={this.handleOpenDrawer}
                    >
                        <MenuRoundedIcon />
                    </IconButton>
                </Hidden>
                <Box sx={webStyle.logoBox}>
                    <img src={homecure} alt="logo" width="45px" />
                    <Typography style={webStyle.typography}>HomeCure</Typography>
                </Box>
                <Hidden mdUp implementation="css">
                    {!this.props.hideNotification && (
                        <IconButton
                            style={webStyle.iconButton}
                            data-test-id="notification"
                            onClick={this.handleNotificationDrawer}
                        >
                            <NotificationsRoundedIcon />
                        </IconButton>
                    )}
                </Hidden>
            </Box>
        );
    }

    renderModalMeta = (modalType: string): IModalMeta => {
        if (modalType === ModalType.INSURANCE_MODAL) {
            return {
                modalTitle: webStyle.insuranceModalTitle,
                modalStyle: webStyle.insuranceModalStyle,
                modalHeader: webStyle.insuranceModalTitleWrapper,
                title: 'View Insurance',
                onClose: this.handleCloseInsuranceModal
            }
        }
        else {
            return {
                modalTitle: webStyle.HealthDetailsModalTitle,
                modalStyle: webStyle.healthDetailsModalStyle,
                modalHeader: webStyle.healthDetailsModalTitleWrapper,
                title: 'Health Details',
                onClose: this.handleCloseHealthDetailsModal
            };
        }
    }

    renderModalContent = (modaType: string) => {

        switch (modaType) {
            case "health-details":
                const healthDetailsAttributes = this.state.healthDetails?.data.attributes!;
                const healthDetails = [
                    {
                        label1: 'Disease Name',
                        label2: 'MM / YYYY',
                        key: healthDetailsAttributes?.diseases.length > 0 ? healthDetailsAttributes?.diseases.map((disease, index) => <Box>{index + 1}. {disease.disease_name}</Box>) : "No",
                        value: healthDetailsAttributes?.diseases?.length > 0 ? healthDetailsAttributes?.diseases.map((disease, index) => <Box>{index + 1}. {this.getMonth(disease.disease_months)} / {disease.disease_years}</Box>) : "No"
                    },
                    {
                        label1: 'Height',
                        label2: 'Weight',
                        key: `${healthDetailsAttributes?.patient_info.height ?? "0"} cm`,
                        value: `${healthDetailsAttributes?.patient_info.weight ?? "0"} Kg`,
                    },
                    {
                        label1: 'Medicine',
                        label2: 'Previous Surgery',
                        key: this.renderHealthParameterList(healthDetailsAttributes?.medicines),
                        value: this.renderHealthParameterList(healthDetailsAttributes?.surgeries)
                    },
                    {
                        label1: 'Allergies',
                        label2: 'Previous Hospital',
                        key: this.renderHealthParameterList(healthDetailsAttributes?.allergies),
                        value: this.renderYesNo(healthDetailsAttributes?.patient_info.previous_hospital)
                    },
                    {
                        label1: 'Smoke',
                        label2: 'Drink',
                        key: this.renderYesNo(healthDetailsAttributes?.patient_info.smoke),
                        value: this.renderYesNo(healthDetailsAttributes?.patient_info.drink)
                    },
                    {
                        label1: 'Prescription',
                        label2: 'Reports',
                        key: healthDetailsAttributes?.prescriptions.length > 0 ? healthDetailsAttributes?.prescriptions.map((prescription, index) => <Box onClick={() => this.openLink(prescription.url)}>{index + 1}. <span style={{ color: '#3780E0' }}> Prescription </span></Box>) : "Not Available",
                        value: healthDetailsAttributes?.reports.length > 0 ? healthDetailsAttributes?.reports.map((report, index) => <Box onClick={() => this.openLink(report.url)}>{index + 1}. <span style={{ color: '#3780E0' }}> Report </span></Box>) : "Not Available",
                    }
                ]
                return healthDetails.map((detail) => <Box style={{ marginTop: '24px', }}>
                    <Box style={webStyle.healthDetailsLabelWrapper}>
                        <Typography style={webStyle.healthDetailLabels}>{detail.label1}</Typography>
                        <Typography style={webStyle.healthDetailLabels}>{detail.label2}</Typography>
                    </Box>
                    <Box style={webStyle.healthDetailsWrapper}>
                        <Typography style={webStyle.healthDetailsKey}>{detail.key}</Typography>
                        <Typography style={webStyle.healthDetailsValue}>{detail.value}</Typography>
                    </Box>
                </Box>)
            case "insurance-modal": return <Box>
                <Typography style={webStyle.insuranceHeader}>Selected Insurance</Typography>
                {
                    this.state.insuranceList.map((insurance) => (
                        <>
                            <Typography style={webStyle.insurance}>{insurance.name}</Typography>
                            <Divider style={webStyle.insuranceDivider} />
                        </>
                    ))
                }
            </Box>
        }
    }


    renderHealthParameterList = (array: { name: string }[]) => {
        return array?.length > 0 ? array.map((disease, index) => <Box>{index + 1}. {disease.name}</Box>) : "No"
    }

    renderYesNo = (conditionalElement: boolean) => {
        return conditionalElement ? "Yes" : "No"
    }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ width: '100%' }}>
            {this.renderTopBar()}
            <Box sx={webStyle.container}>
                <Box sx={webStyle.sidebarWrapper}>
                    <Hidden smDown implementation="css">
                        <Sidebar data-test-id='side-bar' expandMenu={this.state.isSidebarOpen} toggleSidebar={this.toggleExpandSidebar} />
                    </Hidden>
                    <Hidden xsUp implementation="css">
                        <Drawer
                            style={{ height: '100%' }}
                            open={this.state.openDrawer}
                            onClose={this.handleOpenDrawer}
                            ModalProps={{
                                keepMounted: true,
                            }}>
                            <Sidebar expandMenu={this.state.isSidebarOpen} toggleSidebar={this.toggleExpandSidebar} />
                        </Drawer>
                    </Hidden>
                </Box>
                <Box sx={this.state.contentStyle}>
                    {this.props.children}
                </Box>
                {!this.state.isMobileScreen && <div>
                    {this.renderNotification()}
                </div>}
                {this.state.isMobileScreen && <div>
                    <Drawer
                        style={{ height: '100%' }}
                        open={this.state.openNotificationDrawer}
                        onClose={this.handleNotificationDrawer}
                        ModalProps={{
                            keepMounted: true,
                        }}>
                        {this.renderNotification()}
                    </Drawer>
                </div>}
            </Box>

            <CommonModal
                data-test-id='modal'
                open={this.state.modalOpen}
                closeIcon
                dialogStyle={this.renderModalMeta(this.state.modalType).modalStyle}
                titleBoxStyle={this.renderModalMeta(this.state.modalType).modalHeader}
                titleStyle={this.renderModalMeta(this.state.modalType).modalTitle}
                modalTitle={this.renderModalMeta(this.state.modalType).title}
                onClose={this.renderModalMeta(this.state.modalType).onClose}
                contentBoxStyle={{ paddingBottom: '32px' }}
            >
                {this.renderModalContent(this.state.modalType)}
            </CommonModal>
            <Loader loading={this.state.isLoading} />
        </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
    healthDetailsKey: {
        color: '#292929',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '18px',
        cursor: 'pointer',
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as const,
        '&:hover': {
            overflow: 'auto',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
        }
    },
    healthDetailsValue: {
        color: '#292929',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '18px',
        width: '100%',
        cursor: 'pointer',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as const,
        '&:hover': {
            overflow: 'auto',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
        }
    },
    healthDetailsLabelWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '14px'
    },
    healthDetailLabels: {
        color: '#808080',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '16px',
        width: '100%'
    },
    healthDetailsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    insuranceHeader: {
        color: '#808080',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '16px',
        marginBottom: '20px'
    },
    insurance: {
        color: '#292929',
        fontSize: '16px',
        fontFamily: 'SF Pro Text Medium',
    },
    insuranceDivider: {
        backgroundColor: '#F0F0F0',
        margin: '10px 0 14px 0'
    },
    HealthDetailsModalTitle: {
        color: '#292929',
        fontSize: '28px',
        fontFamily: 'SF Pro Text Bold',
        lineHeight: '33px'
    },
    healthDetailsModalStyle: {
        borderRadius: '25px',
        width: '480px'
    },
    healthDetailsModalTitleWrapper: {
        paddingTop: '41px'
    },
    insuranceModalTitle: {
        textAlign: 'center',
        width: '100%',
        color: '#292929',
        fontSize: '20px',
        fontFamily: 'SF Pro Text Bold',
    } as React.CSSProperties,
    insuranceModalStyle: {
        width: '390px',
        borderRadius: '25px'
    },
    insuranceModalTitleWrapper: {
        paddingTop: '34px'
    },
    notificationLoader: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: "40px"
    },
  sidebarWrapper: {
      minHeight: 'max(100vh, 700px)'
  },
  notificationWrapper: {
      minWidth: '340px',
      height: '100vh',
      overflowY: 'auto',
      position: 'fixed',
      right: 0,
      border: '1px solid #EFEFEF',
      backgroundColor: 'white',
      scrollbarColor: "#d9d5d5 #fff",
      scrollbarWidth: "thin",
      zIndex:900
  } as React.CSSProperties,
  container: {
      height: "100%",
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: "100vh",
      overflowX: "hidden" as const
  },
  topBar: {
      backgroundColor: '#014866',
      padding: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'fixed',
      width: '100%',
      boxSizing: 'border-box',
      zIndex: 1000,
  } as React.CSSProperties,
  iconButton: {
      backgroundColor: '#FFFFFF',
      borderRadius: '5px',
      height: '40px',
      width: '40px',
      color: '#000000',
  },
  logoBox: {
      display: 'flex',
      alignItems: 'center',
      gridColumnGap: '20px',
  },
  typography: {
      fontFamily: 'SF Pro Text Heavy',
      fontSize: '16px',
      color: 'white',
  },
  notification: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderBottom: '1px solid #EFEFEF',
      paddingTop: '46px',
      paddingBottom: '40px',
  },
  avatar: {
      height: '95px',
      width: '95px',
      marginBottom: '15px',
  },
  userName: {
      fontFamily: 'SF Pro Text Semibold',
      fontSize: '20px',
      marginBottom: '10px',
  },
  userPhone: {
      color: '#808080',
      fontFamily: 'SF Pro Text Regular',
      fontSize: '18px',
  },
  notificationSection: {
      padding: '40px 22px 34px 34px',
  },
  notificationCategory: {
      width: '285px',
  },
  categoryTitle: {
      color: '#000000',
      fontFamily: 'SF Pro Text Bold',
      fontSize: '16px',
      lineHeight: '20px',
      marginBottom: '20px',
  },
  notificationItem: {
      display: 'flex',
      gridColumnGap: '17px',
  },
  notificationContent: {
      display: 'flex',
      flexDirection: 'column',
    //   gridRowGap: '10px',
  },
  mainContent: {
      color: '#808080',
      fontFamily: 'SF Pro Text Regular',
      fontSize: '14px',
      lineHeight: '22px',
  },
  footerContent: {
      display: 'flex',
      justifyContent: 'space-between',
  },
  name: {
      color: '#000000',
      fontFamily: 'SF Pro Text Bold',
      fontSize: '14px',
      lineHeight: '16px',
  },
  time: {
      color: '#B2B2B2',
      fontFamily: 'SF Pro Text Regular',
      fontSize: '14px',
      lineHeight: '16px',
      marginTop:'6px'
  },
  links: {
      color: '#3780E0',
      fontFamily: 'SF Pro Text Regular',
      fontSize: '14px',
      lineHeight: '16px',
      cursor:'pointer',
      marginTop:'10px'
  },
  buttonsWrapper: {
      display: 'flex',
      gridColumnGap: '24px',
      marginTop: '10px',
  },
  button: {
      height: '42px',
      width: '100px',
  },
  divider: {
      height: '1px',
      width: '157px',
      margin: '20px 0',
  },
  notificationBold:{
    fontFamily:'SF Pro Text Semibold',
    fontSize:'14px',
    color:'black'
  }
};
// Customizable Area End
