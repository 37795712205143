import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";

type FormErrors = {
  user_name: string;
  email: string;
  phone: string;
  message: string;
}

type State = {
  formValues: FormErrors;
  formErrors: FormErrors;
  message: string;
  messageType: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  formValues: FormErrors,
  formErrors: FormErrors,
  message: string;
  messageType: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  setContactApiCallId:any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.setContactApiCallId = "";

    this.state = {
      formValues: {
        user_name: "",
        email: "",
        phone: "",
        message: "",
      },
      formErrors: {
        user_name: "",
        email: "",
        phone: "",
        message: "",
      },
      message:"",
      messageType:""
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId===this.setContactApiCallId) {
        if (responseJson.help_center) {
          this.setState({ message: "Thank you for reaching out! We’ll respond to your query as soon as possible.", messageType:"Success" })
        } else {
          this.setState({ message: responseJson.errors, messageType:"Error" })
        }
      }
    } 
    // Customizable Area End
  }


  // Customizable Area Start
  validationSchema = Yup.object().shape({
    user_name: Yup.string().required("Name is required").matches(/^\S.*$/, 'Name is required').
    test('is-valid-name', 'Name should contain only alphabets', function (value) {
      if (value !== null && value !== '') { return /^[a-zA-Z\s]+$/.test(value); }
      return true;
    }),
    email: Yup.string().email("Invalid email format").required("Email is required").matches(/^\S.*$/, 'Email is required'),
    phone: Yup.string()
    .required("Phone number is required")
    .test('is-valid-phone', 'Invalid phone number', function (value) {
      if (value !== null && value !== '') {
        return /^[6-9]\d{9}$/.test(value);
      }
      return true;
    }),
    message: Yup.string().required("Message is required").matches(/^\S.*$/, 'Message is required'),
  });
  
  handleChange = async (field: string, value: string) => {
    const { formValues, formErrors } = this.state;
    const updatedFormValues = { ...formValues, [field]: value };

    try {
      await this.validationSchema.validateAt(field, updatedFormValues);
      this.setState({
        formValues: updatedFormValues,
        formErrors: { ...formErrors, [field]: "" },
      });
    } catch (validationError: any) {
      this.setState({
        formValues: updatedFormValues,
        formErrors: { ...formErrors, [field]: validationError.message },
      });
    }
  };


  handleSubmit = async () => {
    const { formValues } = this.state;
    let formNext = true;
  
    Object.keys(formValues).forEach((field) => {
      try {
        const fieldValues = {
          [field]: formValues[field as keyof FormErrors],
        };

    this.validationSchema.validateSyncAt(field, fieldValues as FormErrors);
  
    this.setState((prevState) => ({
      ...prevState,
      formErrors: { ...prevState.formErrors, [field]: "" },
    }));
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      const errorMessage = error.message;
      formNext = false;
  
      this.setState((prevState) => ({
        ...prevState,
        formErrors: { ...prevState.formErrors, [field]: errorMessage },
      }));
    }
  }
  
    });
  
    if (formNext) {
      this.addQueryApi();
    }
  };
  
  
  addQueryApi = () => {
    const data = {
        name: this.state.formValues.user_name,
        email: this.state.formValues.email,
        phone_number: `91${this.state.formValues.phone}`,
        description: this.state.formValues.message,
    };

    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.setContactApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactUsApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  closeMessagePopup = () => {
    this.setState({message:"",messageType:""});
    this.moveToNextScreen();
  }

  moveToNextScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "DefaultDashboardPage");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }
  // Customizable Area End
}
