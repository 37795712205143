import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import React from "react";

interface INotification {
  id: string;
  type: string;
  attributes: {
    id: number;
    headings: string;
    notification_type: string | null;
    created_at: string;
    updated_at: string;
    account_id: number;
    notificable_type: string | null;
    notificable_id: string | null;
  };
}
interface NotificationData {
  data: INotification[];
  meta: {
    message: string;
    per_page: number;
    total_count: number;
    page: number;
  };
}

interface InsuranceList {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface HealthDetailResponse {
  data: {
    id: string;
    type: string;
    attributes: PatientHealthAttributes;
  };
}

interface PatientHealthAttributes {
  id: number;
  patient_info: PatientInfo;
  diseases: Disease[];
  medicines: Medicine[];
  surgeries: Surgery[];
  allergies: Allergy[];
  prescriptions: Prescription[];
  reports: Report[];
}

interface PatientInfo {
  height: number | null;
  weight: number | null;
  previous_hospital: boolean;
  smoke: boolean;
  smoke_months: number | null;
  smoke_years: number | null;
  drink: boolean;
  drink_months: number | null;
  drink_years: number | null;
}

interface Disease {
  id: number;
  disease_name: string;
  disease_months: string | null;
  disease_years: number | null;
  custom_disease: string | null;
}

type Medicine = { id: number, name: string, days: number };
type Surgery = { id: number, name: string, surgery_months: string };
type Allergy = { id: number, name: string };
type Prescription = { id: number, url: string };
type Report = { id: number, url: string };

interface IProfile {
  data: {
    attributes: {
      name: string;
      profile_image_url: { url: string };
      phone_number: string
    }
  }
}

enum ModalType {
  HEALTH_DETAILS = 'health-details',
  INSURANCE_MODAL = 'insurance-modal',
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  hideNotification?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  token: any;
  openDrawer: boolean;
  openNotificationDrawer: boolean;
  isSidebarOpen: boolean;
  isMobileScreen: boolean
  expandMenu: boolean;
  contentStyle: React.CSSProperties | {};
  notificationList: Record<string, INotification[]>;
  profileName: string;
  profileImage: string;
  phoneNumber: string;
  intervalId: unknown;
  currentPage: number;
  totalNotifications: number;
  prevScrollTop: number;
  isNotificationLoading: boolean;
  notificationArr: INotification[];
  userType: string;
  modalType: ModalType;
  modalOpen: boolean;
  healthDetails: HealthDetailResponse | null;
  insuranceList: InsuranceList[];
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  getNotificationApiCallId: string = "";
  getProfileDetailsApiCallId: string = "";
  ApiCallId: string = "";
  getInsuranceListApiCallId: string = "";
  scrollContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      token: "",
      openDrawer: false,
      isSidebarOpen: false,
      isMobileScreen: false,
      openNotificationDrawer: false,
      expandMenu: false,
      contentStyle: {
        minHeight: '100vh',
        width: '100%',
        marginTop: window.innerWidth < 960 ? "80px" : 0,
        marginRight: this.props.hideNotification ? 0 : '340px',
        marginLeft: window.innerWidth < 960 ? 0 : '132px'
      },
      notificationList: {},
      profileImage: "",
      profileName: '',
      phoneNumber: "",
      intervalId: '',
      isNotificationLoading: false,
      totalNotifications: 0,
      currentPage: 1,
      prevScrollTop: 0,
      notificationArr: [],
      userType: "",
      modalType: ModalType.HEALTH_DETAILS,
      modalOpen: false,
      healthDetails: null,
      insuranceList: [],
      isLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const userType = await getStorageData("userType");
    const marginBasedOnMenuState = this.state.isSidebarOpen ? '268px' : '132px';
    const notificationViewMargin = this.props.hideNotification ? 0 : '340px';
    const isMobileScreen = window.innerWidth < 960;
    if (!this.props.hideNotification) {
      this.callNotificationsOnInterval();
    }
    this.setState({
      userType: userType,
      isMobileScreen: window.innerWidth < 960,
      contentStyle: {
        minHeight: '100vh',
        width: '100%',
        marginTop: isMobileScreen ? '80px' : 0,
        marginRight: isMobileScreen ? 0 : notificationViewMargin,
        marginLeft: isMobileScreen ? 0 : marginBasedOnMenuState
      }
    }, () => {
      if (!this.props.hideNotification) {
        this.getInAppNotifications();
      }
      this.getProfileDetails()
    })
    window.addEventListener('resize', this.handleResize);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getNotificationApiCallId:
            this.handleNotificationResponse(responseJson);
            break;
          case this.getProfileDetailsApiCallId:
            this.handleProfileDetails(responseJson);
            break;
          case this.getInsuranceListApiCallId:
            const insuranceList = responseJson as InsuranceList[];
            this.setState({ insuranceList: insuranceList, isLoading: false }, () => {
              this.handleOpenInsuranceModal();
            })
            break;
        }

      } else {
        this.setState({ isNotificationLoading: false, isLoading: false })
        if (responseJson?.errors === configJSON.InvalidUserMessage || responseJson?.errors?.message === configJSON.TokenHasExpired || responseJson?.errors?.message === configJSON.InvalidToken) {
          await this.clearLocalStorageAndLogout();
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  iconBellProps = {
    source: imgBell,
  };

  handleNotificationResponse = (responseJson: NotificationData) => {
    const notificationResp = responseJson;
    this.setState((prevState) => {
      const allNotification = [...prevState.notificationArr, ...notificationResp.data];
      const uniqueMap = new Map();
      allNotification.forEach(object => {
        if (!uniqueMap.has(object.id)) {
          uniqueMap.set(object.id, object);
        }
      });
      const newArray = Array.from(uniqueMap.values()) as INotification[];
      newArray.sort((a, b) => moment(b.attributes.created_at).valueOf() - moment(a.attributes.created_at).valueOf());
      return {
        notificationList: this.groupNotificationsByDate(newArray),
        totalNotifications: notificationResp?.meta?.total_count,
        isNotificationLoading: false,
        notificationArr: allNotification
      }
    })
  }

  handleProfileDetails = (responseJson: IProfile) => {
    const profileResp = responseJson;
    const phoneNumber = profileResp.data?.attributes?.phone_number;
    const profileName = profileResp.data?.attributes?.name;
    const profileImage = profileResp.data?.attributes?.profile_image_url?.url;
    this.setState({ profileImage, profileName, phoneNumber })
  }

  clearLocalStorageAndLogout = async () => {
    await removeStorageData('authToken');
    await removeStorageData('isLogin');
    await removeStorageData('userType')
    await removeStorageData('isSignUp')
    await removeStorageData('phoneNumber')
    await removeStorageData('doctorId')
    await removeStorageData('hpUserRole')
    await removeStorageData('priceMode')
    await removeStorageData('redirectFromDashboard')
    await removeStorageData('userName')
    await removeStorageData('serviceType')
    await removeStorageData('isConnectedHospital')
    await removeStorageData('profile');
    this.goToBlock('UserRole');
  }

  goToBlock = (blockName: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), blockName);
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }

  callNotificationsOnInterval = () => {
    const intervalId = setInterval(async () => {
      this.getInAppNotifications();
    }, 10000);
    this.setState({ intervalId })
  }

  async componentWillUnmount() {
    clearInterval(this.state.intervalId as string)
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    const marginBasedOnMenuState = this.state.isSidebarOpen ? '268px' : '132px';
    const notificationViewMargin = this.props.hideNotification ? 0 : '340px'
    if (prevState.isSidebarOpen !== this.state.isSidebarOpen || prevState.isMobileScreen !== this.state.isMobileScreen) {
      this.setState({
        contentStyle: {
          minHeight: '100vh',
          width: '100%',
          marginTop: this.state.isMobileScreen ? '80px' : 0,
          marginRight: this.state.isMobileScreen ? 0 : notificationViewMargin,
          marginLeft: this.state.isMobileScreen ? 0 : marginBasedOnMenuState
        }
      })
    }

    if (prevState.currentPage !== this.state.currentPage) {
      this.getInAppNotifications()
    }
  }

  groupNotificationsByDate(notifications: INotification[]) {
    return notifications.reduce((acc, notification) => {
      const createdDate = moment(notification.attributes.created_at);
      const today = moment();
      const yesterday = moment().subtract(1, "days");

      let key: string;
      if (createdDate.isSame(today, "day")) {
        key = "Today";
      } else if (createdDate.isSame(yesterday, "day")) {
        key = "Yesterday";
      } else {
        key = createdDate.format("DD/MM/YYYY");
      }

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(notification);

      return acc;
    }, {} as Record<string, INotification[]>);
  }

  onScroll = () => {
    const element = this.scrollContainerRef.current;
    if (element && !this.state.isNotificationLoading) {
      const { clientHeight, scrollTop, scrollHeight } = element;
      const totalLength = Object.values(this.state.notificationList).reduce((sum, group) => sum + group.length, 0);
      const isCountEqual = totalLength < this.state.totalNotifications;
      if (scrollTop > this.state.prevScrollTop && scrollTop + clientHeight >= scrollHeight - 10 && isCountEqual) {
        this.setState(prevState => ({
          currentPage: Math.max(1, prevState.currentPage + 1),
        }));
      }
      this.setState({ prevScrollTop: scrollTop });
    }
  }

  handleOpenInsuranceModal = () => {
    this.setState({ modalOpen: true, modalType: ModalType.INSURANCE_MODAL })
  }

  handleCloseHealthDetailsModal = () => {
    this.setState({ modalType: ModalType.HEALTH_DETAILS, modalOpen: false })
  }

  handleCloseInsuranceModal = () => {
    this.setState({ modalType: ModalType.INSURANCE_MODAL, modalOpen: false })
  }

  getMonth = (month: string | null): string => {
    const monthMap: Record<string, string> = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12',
    };

    return monthMap[month || ""] || '01';
  };

  openLink = (url: string) => {
    window.open(url, "blank")
  }

  getNotifications() {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id: number) {
    console.log(id);
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }

  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    let interval;
    interval = seconds / 2592000;
    if (interval > 1) {
      const time = Math.floor(interval);
      return time === 1 ? `${time} month` : `${time} months`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
      const time = Math.floor(interval);
      return time === 1 ? `${time} day` : `${time} days`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      const time = Math.floor(interval);
      return time === 1 ? `${time} hour` : `${time} hours`;
    }
    interval = seconds / 60;
    if (interval > 1) {
      const time = Math.floor(interval);
      return time === 1 ? `${time} min` : `${time} mins`;
    }
    return Math.floor(seconds) + " sec";
  }
  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }


  handleOpenDrawer = () => {
    this.setState({ openDrawer: !this.state.openDrawer })
  }

  handleNotificationDrawer = () => {
    this.setState({ openNotificationDrawer: !this.state.openNotificationDrawer })
  }

  handleResize = () => {
    const screenWidthSmall = window.innerWidth < 960;
    this.setState({ isMobileScreen: screenWidthSmall, openDrawer: false, isSidebarOpen: false })
  }

  toggleExpandSidebar = (isOpen: boolean) => {
    this.setState({ isSidebarOpen: !isOpen })
  }

  getProfileEndPoint = async () => {
    const userType = await getStorageData("userType");
    switch (userType) {
      case "hospital": return configJSON.HospitalProfileEndPoint;
      case "patient": return configJSON.PatientProfileEndPoint;
      default: return configJSON.HPProfileEndPoint
    }
  }

  getInAppNotifications = async (page?: number) => {
    this.setState({ isNotificationLoading: true })
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getNotificationApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getNotificationEndPoint}?per_page=10&page=${page ?? this.state.currentPage}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getDataMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getProfileDetails = async () => {
    const token = await getStorageData("authToken");
    const endPoint = await this.getProfileEndPoint()
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProfileDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getDataMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInsuranceList = async (bookingId: string | null) => {
    this.setState({ isLoading: true })
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getInsuranceListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.InsuranceListEndPoint}${bookingId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getDataMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
