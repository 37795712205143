import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
import * as Yup from "yup";
import { PrescriptionValue } from "./types";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

interface S {
  loading: boolean;
  activeStep: number;
  hideShowDropDown: boolean;
  selectedDiagnosis: any;
  consultType: string;
  errors: Partial<PrescriptionValue>;
  hospitalName: string;
  city: string;
  hpName: string;
  degree: string;
  date: string;
  time: string;
  age: string;
  consultId: string;
  patientName: string;
  bloodPressure: string;
  height: string;
  weight: string;
  pulse: string;
  temperature: string;
  selectedComplaints: any;
  detail: any;
  diagnosis:any
  generalAdvice:any;
  gender: string;
  bloodPressureDecared: boolean;
  pulseDeclared: boolean;
  temperatureDeclared: boolean;
  heightDeclared: boolean
  weightDeclared: boolean;
  repeatType: any;
  dayType: any;
  medicineTakenTime: any;
  dosageCount: number;
  durationCount: number;
  appointmentUpcoming: any;
  appointmentPast: any
  steps: any;
  title: any;
  gendeOption: any;
  diagnostic: any;
  durations: any;
  medicineOptions: any;
  diseasesList: any;
  hideDiseasesShowDropDown: boolean;
  bookingType: string;
  profileData: any;
  isHealthDetailsPopupOpen: boolean;
  healthDetails: any;
  role:string;
  selectedTitle:string;
  prescribed_medications_attributes:any;
  signature_path:any;
  signatureImage:any;
  messageType:string;
  message:string;
  isDropdownOpen: boolean;
  prescriptions:any;
  prescription:any;
  everyDay: string;
  day:number;
  durationName:string;
  isDropdown: any;
}

interface SS {
  id: number;
}

export default class HpOrderManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  getDiseasesApiCallId: string = "";
  getDiagnosisApiCallId: string = "";
  getHPApiCallId:string = "";
  getMedicsApiCall: string = "";
  paitentHealthDetailsAPICallId: string = "";
  setPrescriptionAPICallId: string = "";
  fileInputRef: any;
  setPrescriptionApiCallId:string = "";
  getPrescriptionApiCallId:string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
  ];

    this.state = {
      loading: true,
      activeStep: 0,
      hideShowDropDown: false,
      selectedDiagnosis: [],
      consultType: "online",
      errors: {},
      hospitalName: "",
      city: "",
      hpName: "",
      degree: "",
      date: "",
      time: "",
      age: "",
      consultId: "",
      patientName: "",
      bloodPressure: "",
      height: "",
      weight: "",
      pulse: "",
      temperature: "",
      selectedComplaints: [],
      detail:["","",""],
      generalAdvice:["","",""],
      gender: "Female",
      bloodPressureDecared: false,
      pulseDeclared: false,
      temperatureDeclared: false,
      heightDeclared: false,
      weightDeclared: false,
      repeatType: [],
      dayType: [],
      medicineTakenTime: [],
      dosageCount: 1,
      durationCount: 1,
      appointmentUpcoming: [],
      appointmentPast: [],
      steps: [
        { label: 'Doctor Details' },
        { label: 'Patient Details' },
        { label: 'Diagonsis' },
        { label: 'Advice' },
      ],
      title: [
        {
          value: 'Dr.',
          label: 'Dr.',
        },
        {
          value: 'Ms.',
          label: 'Ms.',
        },
        {
          value: 'Mrs.',
          label: 'Mrs.',
        },
        {
          value: 'Mr.',
          label: 'Mr.',
        },
        {
          value: 'Mr.',
          label: 'Mr.',
        },
      ],
      gendeOption: [
        {
          value: 'Male',
          label: 'Male',
        },
        {
          value: 'Female',
          label: 'Female',
        },
        {
          value: 'Other',
          label: 'Other',
        },
      ],
      diagnostic: [],
      durations: [
        {
          value: 'day',
          label: 'Day',
        },
        {
          value: 'week',
          label: 'Week',
        },
        {
          value: 'month',
          label: 'Month',
        },
      ],
      medicineOptions: [
        { id: "tablets", label: "Tablets" },
        { id: "injection", label: "Injection" },
        { id: "capsule", label: "Capsule" },
        { id: "syrup", label: "Syrup" },
      ],
      diseasesList: [],
      hideDiseasesShowDropDown: false,
      bookingType: "",
      profileData: [],
      isHealthDetailsPopupOpen: false,
      healthDetails: {},
      role:"",
      selectedTitle:"Dr.",
      diagnosis:["","",""],
      prescribed_medications_attributes: [
        {
          name: "",
          medication_type: "Tablets",
          dosage: "1",
          duration: "1 week",
          time_of_the_day: [],
          repeat: "",
          to_be_taken: [],
          durationCount:1,
        },
      ],
      signature_path:null,
      signatureImage:"",
      messageType:"",
      message:"",
      isDropdownOpen: false,
      prescriptions:null,
      prescription:null,
      everyDay: "Every 1 days",
      day:1,
      durationName:"week",
      isDropdown: false,
    };
    this.fileInputRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        this.handleResponse(message);
    }
}

handleResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
      if(apiRequestCallId===this.getDiseasesApiCallId) {
        this.getDisesesData(responseJson)
      }else  if(apiRequestCallId===this.paitentHealthDetailsAPICallId) {
        this.getPaitentHealthDetails(responseJson)
      }else  if(apiRequestCallId===this.getMedicsApiCall) {
        this.getMedicsData(responseJson)
      } else  if(apiRequestCallId===this.getDiagnosisApiCallId) {
        this.getDiagnosis(responseJson)
      } else  if(apiRequestCallId===this.getHPApiCallId) {
        this.getPatientHPDetails(responseJson)
      } else  if(apiRequestCallId===this.setPrescriptionApiCallId) {
        this.setPrescription(responseJson)
      } else  if(apiRequestCallId===this.getPrescriptionApiCallId) {
        this.getPrescriptionData(responseJson)
      }        
}

  getPrescriptionData = (responseJson:any)=> {
    if(responseJson.error){
      this.setState({messageType:"Error",message:responseJson.error})
    }else {
      this.setState({prescriptions:responseJson?.prescriptions?.data})
    }
  }

  setPrescription = (responseJson:any)=> {
    if(responseJson.error){
      this.setState({messageType:"Error",message:responseJson.error})
    }else {
      this.setState({messageType:"Success",message:"Prescription created successfully."})
    }
  }

  getPatientHPDetails = (responseJson:any) => {
    if (!responseJson.errors) {
      this.setState({ hpName:responseJson.healthcare_person_name,role:responseJson.title,hospitalName:responseJson.hospital_name,city:responseJson.hospital_place,degree:responseJson?.degree ? responseJson?.degree : "" })
    } else {
      this.showAlert("Error", responseJson.errors[0]);
    }
  }

  getDisesesData = (responseJson:any) => {
    if (!responseJson.errors && !responseJson.error) {
      this.setState({ diseasesList: responseJson })
    } else {
      this.showAlert("Error", responseJson.errors ? responseJson.errors.message : responseJson.error.message);
    }
  }

  getPaitentHealthDetails = (responseJson:any) => {
    if (!responseJson.errors && !responseJson.error) {
      this.setState({ healthDetails: responseJson.data, isHealthDetailsPopupOpen: true });
    } else {
      this.showAlert("Error", responseJson.errors ? responseJson.errors.message : responseJson.error.message);
    }
  }

  getMedicsData = (responseJson:any) => {
    if (!responseJson.errors && !responseJson.error) {
      this.setState({ appointmentUpcoming: responseJson.data.attributes.upcoming_bookings, appointmentPast: responseJson.data.attributes.past_bookings, loading: false })
    } else {
      this.setState({ loading: true, appointmentUpcoming: [], appointmentPast: [] })
    }
  }

  getDiagnosis = (responseJson:any) => {
    if (!responseJson.error) {
      this.setState({ diagnostic: responseJson.active_tests })
    } else {
      this.showAlert("Error", responseJson.error.message);
    }
  }

  moveToPrescriptionScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "HealthcarePersonnelPrescription");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }

  backToHpOrderManagementScreen = () => {
    if (this.state.activeStep === 0) {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "HealthcarePersonnelOrderManagement");
      message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(message);
    } else {
      this.setState({ activeStep: this.state.activeStep - 1 })
    }
  }

  showPrescriptionForm = (): void => {
    const { activeStep, patientName, age, gender, time, date, consultId, selectedComplaints, diagnosis, bloodPressure, height, weight,temperature, pulse } = this.state;
    let validationFields: Record<string, any> = {};
    let validationSchema: Yup.ObjectSchema | null = null;
    switch (activeStep) {
      case 0:
        validationFields = { time, date, consultId };
        validationSchema = Yup.object().shape({
          time: this.userPrescriptionValidationSchemas.fields.time,
          date: this.userPrescriptionValidationSchemas.fields.date,
          consultId: this.userPrescriptionValidationSchemas.fields.consultId,
        });
        break;
      case 1:
        validationFields = { patientName, age, selectedComplaints, gender, bloodPressure, height, weight,temperature, pulse };
        validationSchema = Yup.object().shape({
          patientName: this.userPrescriptionValidationSchemas.fields.patientName,
          age: this.userPrescriptionValidationSchemas.fields.age,
          gender: this.userPrescriptionValidationSchemas.fields.gender,
          selectedComplaints: this.userPrescriptionValidationSchemas.fields.selectedComplaints,
          bloodPressure: this.userPrescriptionValidationSchemas.fields.bloodPressure,
          height: this.userPrescriptionValidationSchemas.fields.height,
          weight: this.userPrescriptionValidationSchemas.fields.weight,
          pulse: this.userPrescriptionValidationSchemas.fields.pulse,
          temperature: this.userPrescriptionValidationSchemas.fields.temperature,
        });
        break;
      case 2:
        validationFields = { diagnosis };
        validationSchema = Yup.object().shape({
          diagnosis: this.userPrescriptionValidationSchemas.fields.diagnosis,
        });
        break;
      default:
        this.setState({ activeStep: activeStep + 1 });
        return;
    }
    validationSchema
    .validate(validationFields, { abortEarly: false })
    .then(() => {
      this.setState({ activeStep: activeStep + 1 });
    })
    .catch((err: Yup.ValidationError) => {
      const errors: Record<string, string> = {}; 
      err.inner.forEach((error) => {
        if (error.path) {
          errors[error.path] = error.message;
        }
      });
      this.setState({ errors });
    });
  };

  closeDropdown = () => {
    this.setState({ hideShowDropDown: false })
  }

  hideShowDropDown = () => {
    this.setState({ hideShowDropDown: true });
  }

  hidwDropDown = () => {
    this.setState({ hideShowDropDown: false });
  }

  handleCheckboxChange = (name: string) => {
    this.setState((prevState: any) => {
      const { selectedDiagnosis } = prevState;
      if (selectedDiagnosis.includes(name)) {
        return {
          selectedDiagnosis: selectedDiagnosis.filter((item: string) => item !== name),
        };
      } else {
        return {
          selectedDiagnosis: [...selectedDiagnosis, name],
        };
      }
    });
  };

  setConsultType = (type: string) => {
    this.setState({ consultType: type })
  }

  userPrescriptionValidationSchemas = Yup.object().shape({
    gender: Yup.string()  
    .matches(/^[a-zA-Z\s]+$/, "Gender is required")
    .required("Gender is required"),
    date: Yup.string()
      .matches(/^\S.*$/, 'Date is required')
      .matches(/^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-\d{4}$/, 'Date must be in the format dd-mm-yyyy')
      .test('is-future-date', 'Past dates are not allowed', (value) => {
        if (!value) return false;
        const [day, month, year] = value.split('-').map(Number);
        const enteredDate = new Date(year, month - 1, day).setHours(0, 0, 0, 0);
        return enteredDate >= new Date().setHours(0, 0, 0, 0);
      })
      .required('Date is required'),
    time: Yup.string()
      .matches(/^\S.*$/, 'Time is required') 
      .matches(
        /^(0\d|1\d|2[0-3]):([0-5]\d)$/,
        'Time must be in the format hh:mm (24-hour format)'
      )
      .required('Time is required'),
    consultId: Yup.string()
      .matches(/^\S.*$/, 'Consult Id is required')
      .matches(/^\d{1,6}$/, "Consult Id must be a number with up to 6 digits")
      .required("Consult Id is required"),
    patientName: Yup.string()
      .matches(/^\S.*$/, 'Patient name is required')
      .matches(/^[a-zA-Z\s]+$/, "Enter valid patient name")
      .required("Patient name is required"),
    selectedComplaints: Yup.array()
      .min(1, 'Chief complaint is a required field') 
      .required('Chief complaint is a required field'),
    age: Yup.string()
      .matches(/^(?:\d{1,2}|1[01]\d|110)$/, "Invalid age")
      .test('is-valid-name', 'Invalid age', function (value) {
        if (value > 110) {
          return false
        } else {
          return true
        }
      }).matches(/^\S.*$/, 'Age is required')
      .required("Age is required"),
    selectedTitle: Yup.string()
      .notRequired(),
    bloodPressure: Yup.string()
      .nullable()
      .transform((value) => (value === '' ? null : value))
      .matches(/^\d+$/, "Invalid blood pressure")
      .notRequired(),
    height: Yup.string()
      .nullable()
      .transform((value) => (value === ''? null : value))
      .matches(/^\d+$/, "Invalid height")
      .notRequired(),
    weight: Yup.string()
      .nullable()
      .transform((value) => (value === ''?null : value))
      .matches(/^\d+$/, "Invalid weight")
      .notRequired(),
    pulse: Yup.string()
      .nullable()
      .transform((value)=>(value === ''?null : value))
      .matches(/^\d+$/, "Invalid pulse")
      .notRequired(),
    temperature: Yup.string()
      .nullable()
      .transform((value) => ( value === '' ? null : value ))
      .matches(/^\d+$/, "Invalid temperature")
      .notRequired(),
    detail: Yup.string(),
    diagnosis: Yup.array()
      .test('has-non-empty', 'Plaease add atleast one diagnosis', (value) => {
        return value && value.some((item:string) => item.trim() !== '');
      }),
    generalAdvice: Yup.string()
      .notRequired(),
  });

  toggleDropdown = () => {
    this.setState(prevState => ({ isDropdownOpen: !prevState.isDropdownOpen }));
  };

  handlePrescriptionChanges = (field: keyof PrescriptionValue, value: any) => {
    if(field==='gender'){
      this.setState({isDropdownOpen: false})
    }

    try {
      value = value?.replace(/ {2,}/g, ' ');
      const fieldValues: Partial<PrescriptionValue> = {
        [field]: value,
      };
      this.userPrescriptionValidationSchemas.validateSyncAt(field, fieldValues as PrescriptionValue);

      this.setState((prevState) => ({
        ...prevState,
        errors: { ...prevState.errors, [field]: "" },
        [field]: value
      }));
    } catch (errs) {
      if (errs instanceof Yup.ValidationError) {
        const errorMessage = errs.message;

        this.setState((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, [field]: errorMessage },
          [field]: value,
        }));
      }
    }
  };

  handelDeclaredBy = (field: keyof S, value: boolean) => {
    this.setState((prevState) => ({
      ...prevState,
      [field]: value
    }));
  }

  handleSelectedNames = (diagnostic: any) => {
    const selectedName = diagnostic.filter((item: any) => this.state.selectedDiagnosis.includes(item.id)).map((item: any) => item.name).join(", ");
    return selectedName;
  }

  handleSetDayTypeCheckbox = (index: number, type: string) => {
    this.setState((prevState: any) => {
      const updatedPrescriptions = [...prevState.prescribed_medications_attributes];
      const durationArray = updatedPrescriptions[index].time_of_the_day || [];
      if (durationArray.includes(type)) {
        updatedPrescriptions[index].time_of_the_day = durationArray.filter((item: string) => item !== type);
      } else {
        updatedPrescriptions[index].time_of_the_day = [...durationArray, type];
      }
      
      return { prescribed_medications_attributes: updatedPrescriptions };
    });
  };

  handlemedicineTakenTimeCheckbox  = (index: number, type: string) => {
    this.setState((prevState: any) => {
      const updatedPrescriptions = [...prevState.prescribed_medications_attributes];
      const dayTypeArray = updatedPrescriptions[index].to_be_taken || [];
      if (dayTypeArray.includes(type)) {
        updatedPrescriptions[index].to_be_taken = dayTypeArray.filter((item: string) => item !== type);
      } else {
        updatedPrescriptions[index].to_be_taken = [...dayTypeArray, type];
      }
      
      return { prescribed_medications_attributes: updatedPrescriptions };
    });
    };

  getdiseasesList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDiseasesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.diseasesAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );

    const authToken = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleChipDelete = (id: number) => {
    this.setState((prevState: any) => ({
      selectedComplaints: prevState.selectedComplaints.filter((item: number) => item !== id),
    }));
  };

  hideShowDiseasesDropDown = () => {
    this.setState({ hideDiseasesShowDropDown: !this.state.hideDiseasesShowDropDown })
  }

  closeDiseasesDropdown = () => {
    this.setState({ hideDiseasesShowDropDown: false })
  }

  handleDiseasesCheckboxChange = (name: string) => {
    this.setState((prevState: any) => {
      const { selectedComplaints, errors } = prevState;
      const updatedComplaints = selectedComplaints.includes(name)
        ? selectedComplaints.filter((item: string) => item !== name)
        : [...selectedComplaints, name];
      return {
        selectedComplaints: updatedComplaints,
        errors: {
          ...errors,
          selectedComplaints: updatedComplaints.length > 0 ? '' : errors.selectedComplaints,
        },
      };
    });
  };

  getMedicsDataCall = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMedicsApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOrderManagementAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );

    const authToken = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  formatTimeRange(startTime: string, endTime: string) {
    const formatTime = (time: any) => {
      const newTiming = time.startsWith("0") ? time.slice(1) : time;
      const [timePart, period] = newTiming.split(" ");
      const [hour, minute] = timePart.split(":")
      if (minute === "00") {
        return `${hour} ${period}`
      } else {
        return `${hour}:${minute} ${period}`;
      }
    };
    return `${formatTime(startTime)} - ${formatTime(endTime)}`;
  }

  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();

    const daySuffix = (day: number) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    const months = ["Jan.", "Feb.", "Mar.", "Apr.", "May.", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];

    const formattedDate = `${day}${daySuffix(day)} ${months[date.getMonth()]} ${date.getFullYear()}`;
    return formattedDate;
  }

  showAllBooking = (type: string) => {
    this.setState({ bookingType: type })
  }

  backToHpOrderManagementPage = () => {
    this.setState({ bookingType: "" })
  }

  showAllData = () => {
    return this.state.bookingType === "Past" ? this.state.appointmentPast : this.state.appointmentUpcoming;
  }

  backToPrevPage = async () => {
    const profileType = await getStorageData("profileType")
    const userNavigationId = await getStorageData("userNavigationId")
    let page = "HPUserProfile";
    if (profileType === "hospital") {
      page = "UserProfile"
    }
    const navigateToProfile = new Message(getName(MessageEnum.NavigationMessage));
    navigateToProfile.addData(getName(MessageEnum.NavigationTargetMessage), page);
    navigateToProfile.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigateToProfile.addData(getName(MessageEnum.NavigationScreenNameMessage), userNavigationId);
    this.send(navigateToProfile);
  }

  handelShowProfile = (data:any) => {
    setStorageData('profileData',JSON.stringify(data))
    const messages = new Message(getName(MessageEnum.NavigationMessage));
    messages.addData(getName(MessageEnum.NavigationTargetMessage),"HPProfileView");
    messages.addData( getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(messages);
  }

  showHpProfile = async() => {
    const profileData = await getStorageData("profileData")
    this.setState({profileData:JSON.parse(profileData)});
  }

  handelPreviousPage = async() => {
    const messages = new Message(getName(MessageEnum.NavigationMessage));
    messages.addData(getName(MessageEnum.NavigationTargetMessage),"HealthcarePersonnelOrderManagement");
    messages.addData( getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(messages);
  }

  handleCloseDialog = () => {
    this.setState((prev)=>({...prev,isHealthDetailsPopupOpen:false}))
  }


  getMonthNumber = (monthName: string): number => {
    const monthMap: { [key: string]: number } = {
        January: 1,
        February: 2,
        March: 3,
        April: 4,
        May: 5,
        June: 6,
        July: 7,
        August: 8,
        September: 9,
        October: 10,
        November: 11,
        December: 12,
    };

    return monthMap[monthName] ?? -1;
  };

  fetchPaitentHealthDetails = async (patientId: string) => {
    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.paitentHealthDetailsAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.healthDetailsEndPoint}?id=${parseInt(patientId)}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getDiagnosisTestList = async () => {
    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDiagnosisApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fetchDiagnosisTest}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getHpDetailsList = async () => {
    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHPApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fetchOrderManagementAPi}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleDiagnosisChange = (index:number, value:any) => {
    this.setState((prevState) => {
      const updatedDiagnosis = [...prevState.diagnosis];
      updatedDiagnosis[index] = value;
      return { diagnosis: updatedDiagnosis, errors: { ...prevState.errors, diagnosis: "" }, };
    });
  };
  
  handleAddDiagnosis = () => {
    this.setState((prevState) => ({
      diagnosis: [...prevState.diagnosis, ...Array(10).fill("")],
    }));
  };

  handleDetailsChange = (index:number, value:any) => {
    this.setState((prevState) => {
      const updatedDetails = [...prevState.detail];
      updatedDetails[index] = value;
      return { detail: updatedDetails, errors: { ...prevState.errors, detail: "" }, };
    });
  };
  
  handleAddDetails = () => {
    this.setState((prevState) => ({
      detail: [...prevState.detail, ...Array(10).fill("")],
    }));
  };

  handleGeneralAdviceChange = (index:number, value:any) => {
    this.setState((prevState) => {
      const updatedGeneralAdvice = [...prevState.generalAdvice];
      updatedGeneralAdvice[index] = value;
      return { generalAdvice: updatedGeneralAdvice, errors: { ...prevState.errors, generalAdvice: "" }, };
    });
  };
  
  handleAddGeneralAdvice = () => {
    this.setState((prevState) => ({
      generalAdvice: [...prevState.generalAdvice, ...Array(50).fill("")],
    }));
  };

  handleRepeatChange = (index: number, repeatValue: string) => {
    const updatedPrescriptions = [...this.state.prescribed_medications_attributes];
  
    updatedPrescriptions[index].repeat = 
      updatedPrescriptions[index].repeat === repeatValue ? "" : repeatValue; 
  
    this.setState({ prescribed_medications_attributes: updatedPrescriptions });
  };

  handleDayTypeChange = (index:number, dayTypeValue:any) => {
    const updatedPrescriptions = [...this.state.prescribed_medications_attributes];
    const days = updatedPrescriptions[index].time_of_the_day;
    if (days.includes(dayTypeValue)) {
      updatedPrescriptions[index].time_of_the_day = days.filter((item:any) => item !== dayTypeValue);
    } else {
      updatedPrescriptions[index].time_of_the_day.push(dayTypeValue);
    }
    this.setState({ prescribed_medications_attributes: updatedPrescriptions });
  };

  handlePrescriptionChange = (index: number, field: string, value: any) => {
    const updatedPrescriptions = [...this.state.prescribed_medications_attributes];
    if (field === "duration") {
      updatedPrescriptions[index]["duration"] = `${updatedPrescriptions[index]["durationCount"] || 0} ${value}`;
    } else if (field === "durationCount" && value > 0) {
      updatedPrescriptions[index]["durationCount"] = value;
      const durationParts = updatedPrescriptions[index]["duration"]?.split(' ') || ['', 'days'];
      updatedPrescriptions[index]["duration"] = `${value} ${durationParts[1]}`;
    } else if (field === "dosage" && value > 0) {
      updatedPrescriptions[index]["dosage"] = JSON.stringify(value);
    } else {
      updatedPrescriptions[index][field] = value;
    }
  
    this.setState({
      prescribed_medications_attributes: updatedPrescriptions,
      durationName: field === "duration" ? value : this.state.durationName,
      isDropdownOpen: false,
    });
  };

  handleAddMore = () => {
    const newPrescription = {
      name: "",
      medication_type: "Tablets",
      dosage: "1",
      duration: "1 week",
      time_of_the_day: [],
      repeat: "",
      to_be_taken: [],
      durationCount:1
    };
    this.setState({
      prescribed_medications_attributes: [...this.state.prescribed_medications_attributes, newPrescription],
    });
  };


  handleUploadSignature = (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          signatureImage: reader.result, 
          signature_path: {
            content_type: selectedFile.type,
            filename: selectedFile.name,
            data: typeof reader.result === 'string' ? reader.result.split(',')[1] : null,
          },
        });
      };
      
      reader.readAsDataURL(selectedFile);
  
    }
  };

  callPrescriptionAPI = async() => {
    const data ={
      medic_reservation_id: this.state.consultId, 
      prescription: {
        patient_name: this.state.patientName,
        healthcare_personnel_name: `${this.state.selectedTitle} ${this.state.hpName}`,
        degree: this.state.degree,
        hospital_name: this.state.hospitalName,
        hospital_address: this.state.city,
        date: this.state.date,
        time:this.state.time,
        consult_type: this.state.consultType,
        age: this.state.age,
        gender:this.state.gender,
        signature_path: this.state.signature_path,
        general_advices: this.state.generalAdvice,
        diagnosis_tests: this.state.selectedDiagnosis,
        diagnosis: this.state.diagnosis,
        details: this.state.detail,
        chief_complaint: this.state.selectedComplaints,
        vitals: {
          blood_pressure: this.state.bloodPressure,
          height: this.state.height,
          weight: this.state.weight,
          pulse: this.state.pulse,
          temperature: this.state.temperature,
          declared_by_patient: "Yes"
        },
        prescribed_medications_attributes: this.state.prescribed_medications_attributes
      }
    }
    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setPrescriptionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createPrescriptions}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createAddressMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  closePopup = (messageType:string) => {
    if(messageType==='Success'){
      const messages = new Message(getName(MessageEnum.NavigationMessage));
      messages.addData(getName(MessageEnum.NavigationTargetMessage),"HealthcarePersonnelOrderManagement");
      messages.addData( getName(MessageEnum.NavigationPropsMessage),this.props);
      this.send(messages);
    }

    this.setState({messageType:"",message:""})
  }

  getPrescription = async () => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrescriptionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `prescriptions/${this.props.navigation.getParam("navigationBarTitleText")}?medic_reservation_id=${this.props.navigation.getParam("navigationBarTitleText")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getOrderByIdMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getDayOfWeek = (dateString:any) => {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const date = new Date(dateString);
    return daysOfWeek[date.getDay()];
  };

  callPrescription = (data:any) => {
    setStorageData('prescription',JSON.stringify(data))
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "HealthcareViewPersonnelPrescription");
      message.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), this.props.navigation.getParam("navigationBarTitleText"));
      this.send(message);
  }

  showPrescription = async() => {
    const prescription = await getStorageData("prescription")
    this.setState({prescription:JSON.parse(prescription)});
  }

  moveToPreviouisScreen = () => {
    const messages = new Message(getName(MessageEnum.NavigationMessage));
    messages.addData(getName(MessageEnum.NavigationTargetMessage),"OrderManagementPrescription");
    messages.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    messages.addData(getName(MessageEnum.NavigationScreenNameMessage), this.props.navigation.getParam("navigationBarTitleText"));
    this.send(messages);
  }

  handleDaysTypeChange = (value:any) => {
    const val = parseInt(value)
    this.setState({day:val,everyDay:`Every ${val} days`,isDropdown:false})
  } 

  toggleRepeatDropdown = () => {
    this.setState(prevState => ({ isDropdown: !prevState.isDropdown }));
  };
}