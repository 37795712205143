import React from 'react';
import CustomisableUserProfilesController, { configJSON } from "./CustomisableUserProfilesController";
import { Box, Checkbox, Dialog, FormControlLabel, styled, Typography } from '@material-ui/core';
import HospitalProfileWrapper from './HospitalProfileWrapper.web';
import CustomStyledButton from '../../../components/src/CustomStyledButton.web';
import { checked, unCheckedIcon } from './assets';
import parse, { domToReact, HTMLReactParserOptions, Element as DOMElement } from 'html-react-parser';
import Loader from '../../../components/src/Loader.web';
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";

class HomeCureTermsAndConditions extends CustomisableUserProfilesController {
    async componentDidMount() {
        this.getTermsAndCondition()
    }

    parseOptions: HTMLReactParserOptions = {
        replace: (domNode: unknown) => {
            if (domNode instanceof DOMElement) {
                if (domNode.name === 'p') {
                    return (
                        <Box>
                            <Typography style={webStyle.apiContentWrapper} >
                                {domToReact(domNode.children, this.parseOptions)}
                            </Typography>
                            <br />
                        </Box>
                    );
                } else if (domNode.name === 'a') {
                    return <span style={webStyle.contentLink} onClick={() => this.openLink(domNode.attribs.href)} >
                        {domToReact(domNode.children)}
                    </span>
                }
            }
        }
    };

    successPopup = () => {
        return (
        <Dialog open={this.state.acceptPrivacyPolicy && this.state.status}>
            <>
            <Box data-test-id="success" style={webStyle.messageContainer}>
                <Box style={{ padding: "0px 10px" }}>
                <p style={webStyle.textheading}>Success</p>
                <p style={webStyle.textparagraph}>You have successfully accepted the terms and conditions.</p>
                <button
                    data-test-id='btnClick'
                    style={webStyle.button}
                    onClick={this.changeStatus}
                >
                    OK
                </button>
                </Box>
            </Box>
            </>
        </Dialog>
        );
    };
    
    render() {
        return (
            <Box sx={webStyle.container}>
                <SidebarNotificationWrapper {...this.props} hideNotification >
                    <Box sx={webStyle.rightWrapper}>
                        <HospitalProfileWrapper
                            data-test-id="profile-wrapper"
                            {...this.props}
                            profilePageTitle={configJSON.PageTitle.TermsAndConditions}
                            profileBackBtn
                        >
                            <Box sx={webStyle.contentWrapper} data-test-id='terms-and-condition'>
                                <Typography style={webStyle.headerText}>HomeCure</Typography>
                                <Typography style={webStyle.titleText}>WELCOME TO HOMECURE</Typography>
                                <Typography style={webStyle.subtitleText}>The Best Medical Service of Lucknow City.</Typography>
                                <Typography style={webStyle.featureText}>Check Our All Feature</Typography>
                                {parse(this.state.termsAndCondition, this.parseOptions)}
                                <Box sx={webStyle.actionButtonWrapper}>
                                    <StyledFormControlLabel
                                        checked={this.state.acceptPrivacyPolicy}
                                        data-test-id="agreeTerms"
                                        onChange={this.handleChangePrivacyPolicy}
                                        control={<Checkbox disableRipple data-test-id='row-checkbox' checkedIcon={<img src={checked} alt="checked_icon" />} icon={<img src={unCheckedIcon} alt="unchecked_icon" />} />}
                                        label="I agree with the terms and conditions"
                                        labelPlacement="end"
                                    />
                                    <CustomStyledButton data-test-id='update-preference' onClick={this.updatePreferanceOfTermsAndCondition} style={{ width: '250px' }} label={configJSON.ButtonLabel.Done} />
                                </Box>
                            </Box>
                            <Loader loading={this.state.isLoading} />
                            {this.successPopup()}
                        </HospitalProfileWrapper>
                    </Box>
                </SidebarNotificationWrapper>
            </Box >
        );
    }
}

export default HomeCureTermsAndConditions;

const StyledFormControlLabel = styled(FormControlLabel)({
    '&.MuiFormControlLabel-root': {
        alignItems: 'center',
        gap: '22px',
        marginLeft: '0'
    },
    '& .MuiTypography-root': {
        color: '#292929',
        fontSize: '16px',
        fontFamily: 'SF Pro Text Medium',
        lineHeight: '18px'
    }
})

const webStyle = {
    headerText: {
        color: '#014866',
        fontFamily: 'SF Pro Text Heavy',
        fontSize: '30px',
        lineHeight: '35px',
        marginBottom: '20px',
    },
    apiContentWrapper: {
        fontSize: '18px',
        textAlign: 'justify',
        lineHeight: '26px',
        color: '#292929',
        fontFamily: 'SF Pro Text Regular'
    } as React.CSSProperties,
    contentLink: {
        color: '#014866',
        textDecoration: 'underline',
        lineHeight: '24px',
        fontSize: '16px',
        fontFamily: 'SF Pro Text Regular',
        cursor: 'pointer'
    },
    actionButtonWrapper: {
        flexDirection: "column",
        display: 'flex',
        gridRowGap: "24px",
        marginTop: '67px'
    },
    sidebarWrapper: {
        width: '132px',
        minHeight: 'max(100vh, 700px)',
    },
    container: {
        display: 'flex',
        height: "100%",
        minHeight: "100vh",
        justifyContent: 'space-between',
        overflowX: "hidden" as const,
    },
    rightWrapper: {
        minHeight: '100vh',
        width: '100%',
    },
    contentWrapper: {
        padding: '94px 140px 72px 60px',
    },
    titleText: {
        fontSize: '24px',
        color: '#292929',
        textTransform: 'uppercase',
        fontFamily: 'SF Pro Text Medium',
        marginBottom: '14px',
    } as React.CSSProperties,
    subtitleText: {
        color: '#808080',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '20px',
        marginBottom: '10px',
    },
    featureText: {
        color: '#014866',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Medium',
        marginBottom: '20px',
    },
    descriptionText: {
        color: '#555555',
        textAlign: 'justify',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '18px',
        marginBottom: '10px',
    } as React.CSSProperties,
    additionalText: {
        fontFamily: 'SF Pro Text Regular',
        color: '#555555',
        fontSize: '18px',
        textAlign: 'justify',
    } as React.CSSProperties,
    messageContainer: {
        display: "block",
        maxWidth: "550px",
        width: "500px",
        height: "auto",
        textAlign: "left",
        borderRadius: "5px"
      } as React.CSSProperties,
    textheading: {
    width: "100%",
    color: "black",
    fontFamily: "SF Pro Text Regular",
    fontSize: "28px",
    fontWeight: 700,
    height: "8px",
    marginTop: "15px",
    caretColor: "white"
    },
    textparagraph: {
    width: "100%",
    color: "black",
    fontFamily: "SF Pro Text Regular",
    fontSize: "16px",
    height: "8px",
    margin: "30px 0px",
    caretColor: "white"
    },
    button: {
    backgroundColor: "rgb(33, 150, 243)",
    fontFamily: "SF Pro Text Regular",
    border: "none",
    color: "white",
    padding: "5px",
    marginBottom: "15px",
    borderRadius: "3px",
    fontWeight: 600,
    cursor: "pointer",
    caretColor: "transparent",
    "&.MuiButton-root:hover": {
        backgroundColor: "rgb(33, 150, 243)",
        color: "white"
    }
    }, 
};
