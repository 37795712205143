// Customizable Area Start
import React from "react";
import HpOrderManagementController, { Props } from "../HpOrderManagementController";
import { Box, ThemeProvider, Typography, createTheme, withStyles, Grid, Button, Dialog, DialogContent } from "@material-ui/core";
import Sidebar from "../../../../components/src/Sidebar.web";
import { prescriptionIcon, noData, ProfileIcon } from "./../assets";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SidebarNotificationWrapper from "../../../notifications/src/Notifications.web";
import CloseIcon from "@material-ui/icons/Close";

const theme = createTheme({
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#0000ff",
        }
    }
});
// Customizable Area End

export class HealthcarePersonnelOrderManagement extends HpOrderManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        this.getMedicsDataCall()
    }
    noDataFound = (classes: any) => {
        return (
            <>
                {
                    this.state.loading ? (
                        <>
                            <Box className={classes.noData}>Loading...</Box>
                        </>) : (
                        <>
                            <Box className={classes.noDataDashboardContainer}>
                                <img
                                    src={noData}
                                    width={60}
                                    height={60}
                                    className={classes.closeIcon}
                                />
                                <Box className={classes.noData}>No results found</Box>
                            </Box>
                        </>)
                }
            </>
        )
    }

    btnColor = (classes: any) => {
        return this.state.appointmentUpcoming?.length > 0 ? classes.titleVisibleSeeAll : classes.titleSeeAll
    }

    handelProfile = (data: string) => {
        return data !== null ? data : ProfileIcon
    }

    healthDetailsContainerPopup = (classes: any) => {
        const medicinesCount = this.state.healthDetails?.attributes?.medicines?.length || 0;
        const allergiesCount = this.state.healthDetails?.attributes?.allergies?.length || 0;
        const maxCount = Math.max(medicinesCount, allergiesCount);
        return (
            <DialogContent>
                <Box style={{ display:"flex" }}>
                    <Box style={{width:"50%"  }}>
                        <Box className={classes.dialogContentSpace}>
                            <Typography variant="body2" data-test-id="disessesName" className={classes.healthDetailsLabel}>Disease Name</Typography>
                            {this.state.healthDetails?.attributes?.diseases?.length > 0 ? (
                                this.state.healthDetails?.attributes.diseases.map((disease: any, index: number) => (<Typography variant="body2" key={index} className={classes.healthDetailsValue}>{disease.disease_name}</Typography>))
                            ) : (
                                <Typography variant="body2" className={classes.healthDetailsValue}>-</Typography>
                            )}
                        </Box>
                        <Box className={classes.dialogContentSpace}>
                            <Typography variant="body2" className={classes.healthDetailsLabel}>Height</Typography>
                            <Typography variant="body2" className={classes.healthDetailsValue}>{this.state.healthDetails?.attributes?.patient_info.height} cm</Typography>
                        </Box>
                        <Box className={classes.dialogContentSpace}>
                            <Typography variant="body2" data-test-id="medecine" className={classes.healthDetailsLabel}>Medicine</Typography>
                            {this.state.healthDetails?.attributes?.medicines?.length > 0 ? ([...Array(maxCount)].map((_, index) => (
                                <Box key={index} className={classes.dialogContentSpace}>
                                    <Typography variant="body2" className={classes.healthDetailsValue} style={{ height: "36px" }}>
                                        {index < medicinesCount ? `${index + 1}. ${this.state.healthDetails.attributes.medicines[index].name}` : '-'}
                                    </Typography>
                                </Box>
                            ))
                            ) : (
                                maxCount > 0 ? ([...Array(maxCount)].map((_, index) => (
                                    <Box key={index} className={classes.dialogContentSpace} data-test-id="medicineNotFound"><Typography variant="body2" className={classes.healthDetailsValue} style={{ height: "36px" }}>-</Typography></Box>
                                ))) : (
                                    <Box className={classes.dialogContentSpace}>
                                        <Typography variant="body2" data-test-id="medicines" className={classes.healthDetailsValue} style={{ height: "36px" }}>-</Typography>
                                    </Box>
                                )
                            )}
                        </Box>
                        <Box className={classes.dialogContentSpace}>
                            <Typography variant="body2" data-test-id="prevSurgery" className={classes.healthDetailsLabel}>Previous Surgery</Typography>
                            <Typography variant="body2" data-test-id="surgery" className={classes.healthDetailsValue}>{this.state.healthDetails?.attributes?.surgeries.length > 0 ? "Yes" : "No"}</Typography>
                        </Box>
                        <Box className={classes.dialogContentSpace}>
                            <Typography variant="body2" data-test-id="smokeLabel" className={classes.healthDetailsLabel}>Smoke</Typography>
                            <Typography variant="body2" data-test-id="smoke" className={classes.healthDetailsValue}>No</Typography>
                        </Box>
                        <Box className={classes.dialogContentSpace}>
                            <Typography variant="body2" data-test-id="PrescriptonLabel" className={classes.healthDetailsLabel}>Prescription</Typography>
                            <Typography variant="body2" data-test-id="presAvailable" className={classes.healthDetailsValue}>Not available</Typography>
                        </Box>
                    </Box>
                    <Box style={{width:"50%"}}>
                        <Box className={classes.dialogContentSpace}>
                            <Typography variant="body2" data-test-id="mm/yy" className={classes.healthDetailsLabel}>MM / YYYY</Typography>
                            {this.state.healthDetails?.attributes?.diseases?.length > 0 ? (
                                this.state.healthDetails?.attributes.diseases.map((disease: any, index: number) => (
                                    <Typography data-test-id="month" variant="body2" className={classes.healthDetailsValue}>{this.getMonthNumber(disease.disease_months)} / {disease.disease_years}</Typography>
                                ))
                            ) : (
                                <Typography variant="body2" data-test-id="noData" className={classes.healthDetailsValue}>-</Typography>
                            )}
                        </Box>
                        <Box className={classes.dialogContentSpace}>
                            <Typography variant="body2" className={classes.healthDetailsLabel} data-test-id="weightLabel">Weight</Typography>
                            <Typography variant="body2" className={classes.healthDetailsValue} ata-test-id="weightInfo">{this.state.healthDetails?.attributes?.patient_info.weight} Kg</Typography>
                        </Box>
                        <Box className={classes.dialogContentSpace}>
                            <Typography variant="body2" className={classes.healthDetailsLabel} data-test-id="allergieLabel">Allergies</Typography>
                            {this.state.healthDetails?.attributes?.allergies?.length > 0 ? (
                                [...Array(maxCount)].map((_, index) => (
                                    <Box key={index} className={classes.dialogContentSpace} >
                                        <Typography variant="body2" data-test-id="allergies" className={classes.healthDetailsValue} style={{ height: "36px" }}>
                                            {index < allergiesCount ? `${index + 1}. ${this.state.healthDetails.attributes.allergies[index].name}` : '-'}
                                        </Typography>
                                    </Box>
                                ))
                            ) : (
                                maxCount > 0 ? ([...Array(maxCount)].map((_, index) => (
                                    <Box key={index} className={classes.dialogContentSpace} data-test-id="allergiesNotFound">
                                        <Typography variant="body2" data-test-id="noData" className={classes.healthDetailsValue} style={{ height: "36px" }}>-</Typography>
                                    </Box>
                                ))) : (
                                    <Box className={classes.dialogContentSpace}  data-test-id="allergie">
                                        <Typography variant="body2" data-test-id="noData" className={classes.healthDetailsValue} style={{ height: "36px" }}>-</Typography>
                                    </Box>
                                )
                            )}
                        </Box>
                        <Box className={classes.dialogContentSpace}>
                            <Typography variant="body2" data-test-id="previousLabel" className={classes.healthDetailsLabel}>Previous Hospital</Typography>
                            <Typography variant="body2" data-test-id="previous" className={classes.healthDetailsValue}>{this.state.healthDetails?.attributes?.patient_info.previous_hospital ? "Yes" : "No"}</Typography>
                        </Box>
                        <Box className={classes.dialogContentSpace}>
                            <Typography variant="body2" data-test-id="drinksLabel" className={classes.healthDetailsLabel}>Drink</Typography>
                            <Typography variant="body2" data-test-id="drinks" className={classes.healthDetailsValue}>Yes</Typography>
                        </Box>
                        <Box className={classes.dialogContentSpace}>
                            <Typography variant="body2" data-test-id="reportsLabel" className={classes.healthDetailsLabel}>Reports</Typography>
                            <Typography variant="body2" data-test-id="reports" className={classes.healthDetailsValue}>Not available</Typography>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const allData = this.showAllData();
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    <Box className={classes.orderHPManagementContainer}>
                        {/* <Grid item xs={1}>
                            <Sidebar />
                        </Grid> */}
                        <SidebarNotificationWrapper {...this.props}>
                        <Box>
                            {this.state.bookingType === "" ?
                                (
                                    <Box className={classes.orderHpManagementAvailableContainer}>
                                        <Box className={classes.headerHpContainer}>
                                            <Box className={classes.orderHpHeading}>
                                                <Typography variant="h4" className={classes.profileHPHeading} style={{ textTransform: "capitalize" }}>Bookings</Typography>
                                            </Box>
                                        </Box>
                                        <Box className={classes.prescriptionContainer} data-test-id="moveToPrescriptionScreen" onClick={this.moveToPrescriptionScreen}>
                                            <Box>
                                                <img src={prescriptionIcon} width={56} height={56} className={classes.ePrescriotionImg} />
                                                <Typography className={classes.ePrescriotion}>Create E-Prescription</Typography>
                                            </Box>
                                        </Box>
                                        <Box className={classes.newOrderBookingMainContainer} component="div">
                                            <Box className={classes.newOrderBookingHeadingContainer} component="div">
                                                <Typography variant="h6" className={classes.titleBookingType} style={{ textAlign: "center", textTransform: "capitalize", caretColor: "transparent" }}>Upcoming Bookings</Typography>
                                                <Typography data-test-id="seeHPAllUpcomingBooking" variant="h6" className={this.btnColor(classes)} onClick={() => this.showAllBooking("Upcoming")}>
                                                    See All
                                                </Typography>
                                            </Box>
                                            <Box className={classes.allBookingContainer} data-test-id="cardContainerUpcoming">
                                                {this.state.appointmentUpcoming?.length > 0 ? (
                                                    <>
                                                        {this.state.appointmentUpcoming.slice(0, 2).map((data: any, index: number) => (
                                                            <Box key={index} data-test-id="upcomingIndex">
                                                                <Box className={classes.bookingContainer} data-test-id="subContainerUpcoming">
                                                                    <Box className={classes.bookingWrapper} data-test-id="bookingWrapperUpcoming">
                                                                        <Box className={classes.imageContainer} data-test-id="imageContainerUpcoming">
                                                                            <img data-test-id={`profile${index}`} src={this.handelProfile(data.data.attributes.patient_profile_image)} onClick={() => this.handelShowProfile(data.data.attributes)} className={classes.profileSize} />
                                                                        </Box>
                                                                        <Box className={classes.bookingsInfoSection}>
                                                                            <Box>
                                                                                <Typography variant="h6" data-test-id="nameUpcoming" className={classes.nameLabel}>{data.data.attributes.patient_profile_name	}</Typography>
                                                                                <Typography variant="h6" data-test-id={`hpDetailsUpcoming${index}`} className={classes.healthDetails} onClick={() => this.fetchPaitentHealthDetails(data.data.attributes.id)}>Health Details</Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <Box>
                                                                                    <Typography variant="h6" data-test-id="timeUpcoming" className={classes.time_duration}>{this.formatTimeRange(data.data.attributes.start_time, data.data.attributes.end_time)}</Typography>
                                                                                    <Typography variant="h6" data-test-id="roleUpcoming" className={classes.time}>{this.formatDate(data.data.attributes.created_at)}</Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box className={classes.bookingBtnContainer}>
                                                                </Box>
                                                            </Box>
                                                        ))}
                                                    </>
                                                ) : (this.noDataFound(classes))}
                                            </Box>
                                        </Box>
                                        <Box className={classes.newOrderBookingMainContainer}>
                                            <Box className={classes.newOrderBookingHeadingContainer}>
                                                <Typography variant="h6" className={classes.titleBookingType} style={{ textAlign: "center", textTransform: "capitalize", caretColor: "transparent" }}>Past Bookings</Typography>
                                                <Typography data-test-id="seeHPAllPastBooking" variant="h6" className={this.btnColor(classes)} onClick={() => this.showAllBooking("Past")}>
                                                    See All
                                                </Typography>
                                            </Box>
                                            <Box className={classes.allBookingContainer} data-test-id="cardContainer">
                                                {this.state.appointmentPast?.length > 0 ? (
                                                    <>
                                                        {this.state.appointmentPast.slice(0, 2).map((data: any, index: number) => (
                                                            <Box key={index} className={classes.wrapper}>
                                                                <Box className={classes.bookingContainer} data-test-id="subContainer">
                                                                    <Box className={classes.bookingWrapper} data-test-id="bookingWrapper">
                                                                        <Box className={classes.imageContainer}>
                                                                            <img data-test-id={`profile${index}`} src={this.handelProfile(data.data.attributes.patient_profile_image)} className={classes.profileSize} onClick={() => this.handelShowProfile(data.data.attributes)} />
                                                                        </Box>
                                                                        <Box className={classes.bookingsInfoSection}>
                                                                            <Box>
                                                                                <Typography variant="h6" data-test-id="name" className={classes.nameLabel}>{data.data.attributes.patient_profile_name	}</Typography>
                                                                                <Typography variant="h6" data-test-id="role" className={classes.healthDetails} onClick={() => this.fetchPaitentHealthDetails(data.data.attributes.id)}>Health Details</Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <Box>
                                                                                    <Typography variant="h6" data-test-id="time" className={classes.time_duration}>{this.formatTimeRange(data.data.attributes.start_time, data.data.attributes.end_time)}</Typography>
                                                                                    <Typography variant="h6" data-test-id="role" className={classes.time}>{this.formatDate(data.data.attributes.created_at)}</Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box className={classes.bookingBtnContainer}>
                                                                    <Button className={classes.statusBtn}>Completed</Button>
                                                                </Box>
                                                            </Box>
                                                        )
                                                        )}
                                                    </>
                                                ) : (this.noDataFound(classes))}
                                            </Box>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box>
                                        <Box className={classes.headerBookingContainer}>
                                            <Box className={classes.orderBookingHeading}>
                                                <ArrowBackIosIcon
                                                    className={classes.calendarBookingBackBtn}
                                                    data-test-id="backPrevPage"
                                                    onClick={this.backToHpOrderManagementPage}
                                                />
                                                <Typography variant="h4" className={classes.profileBookingHeading}>{this.state.bookingType} Booking</Typography>
                                            </Box>
                                            <Box>
                                                {allData.map((data: any, index: number) => (
                                                    <Box key={index} className={classes.wrapper}>
                                                        <Box className={classes.bookingContainer} data-test-id="seeAllBookingCcntainer">
                                                            <Box className={classes.bookingWrapper} data-test-id="seeAllBookingWrapper">
                                                                <Box className={classes.imageContainer}>
                                                                    <img data-test-id={`SeeProfile${index}`} src={this.handelProfile(data.data.attributes.patient_profile_image)} className={classes.profileSize} onClick={() => this.handelShowProfile(data.data.attributes)} />
                                                                </Box>
                                                                <Box className={classes.bookingsInfoSection}>
                                                                    <Box>
                                                                        <Typography variant="h6" data-test-id="seeAllName" className={classes.nameLabel}>{data.data.attributes.patient_profile_name	}</Typography>
                                                                        <Typography variant="h6" data-test-id="seeAllRole" className={classes.healthDetails} onClick={() => this.fetchPaitentHealthDetails(data.data.attributes.id)}>Health Details</Typography>
                                                                    </Box>
                                                                    <Box>
                                                                        <Box>
                                                                            <Typography variant="h6" data-test-id="seeAllTime" className={classes.time_duration}>{this.formatTimeRange(data.data.attributes.start_time, data.data.attributes.end_time)}</Typography>
                                                                            <Typography variant="h6" data-test-id="seeAllRole" className={classes.time}>{this.formatDate(data.data.attributes.created_at)}</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                                <>
                                    <Dialog open={this.state.isHealthDetailsPopupOpen} PaperProps={{ style: { borderRadius: "15px", maxWidth: "480px", width: "480px", height: "656px", overflowY: "auto" } }}>
                                        <Box className={classes.dialogWrapperConatiner}>

                                            <Box className={classes.hpTitleConatiner}>
                                                <Typography className={classes.dialogHPTitle}>
                                                    Health Details
                                                </Typography>
                                                <CloseIcon data-test-id="closeDialog" onClick={this.handleCloseDialog} />
                                            </Box>
                                            {this.healthDetailsContainerPopup(classes)}
                                        </Box>
                                    </Dialog>
                                </>
                        </Box>
                        </SidebarNotificationWrapper>
                    </Box>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    orderHPManagementContainer: {
        display: "flex",
    },
    orderHpManagementAvailableContainer: {
        margin: "40px 50px",
        width: "70%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "flex-start",
        alignItems: "flex-start" as "flex-start",
        gap: "20px",
        '@media (max-width: 790px)': {
            width: "100%"
        }
    },
    newOrderBookingMainContainer: {
        minHeight: "25vh",
        width: "122%",
    },
    newOrderBookingHeadingContainer: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "20px",
        alignItems: "center",
    },
    titleBookingType: {
        fontSize: "22px",
        fontFamily: "SF Pro Text Bold",
        color: "#292929",
        '@media (max-width: 650px)': {
            fontSize: "18px"
        }
    },
    titleSeeAll: {
        caretColor: "transparent",
        cursor: "pointer",
        color: "#808080",
        fontSize: "18px",
        fontFamily: "SF Pro Text Medium",
        '@media (max-width: 650px)': {
            fontSize: "16px"
        }
    },
    titleVisibleSeeAll: {
        caretColor: "transparent",
        cursor: "pointer",
        color: "#3780E0",
        fontSize: "18px",
        fontFamily: "SF Pro Text Medium",
        '@media (max-width: 650px)': {
            fontSize: "16px"
        }
    },
    headerHpContainer: {
        width: "85%",
        display: "flex",
        alignItems: "center" as "center",
        justifyContent: "space-between",
        caretColor: "transparent"
    },
    orderHpHeading: {
        display: "flex",
        alignItems: "center" as "center",
        justifyContent: "flex-start",
        gap: "25px",
    },
    profileHPHeading: {
        width: "100%",
        fontSize: "28px",
        fontFamily: "SF Pro Text Bold",
        color: "#292929",
        caretColor: "transparent"
    },
    prescriptionContainer: {
        width: "223px",
        height: "85px",
        borderRadius: "8px",
        border: "1px solid #292929",
        padding: "5%"
    },
    ePrescriotion: {
        fontFamily: "SF Pro Text Bold",
        fontSize: "20px",
        color: "#292929",
        textAlign: "center" as "center"
    },
    ePrescriotionImg: {
        display: "flex",
        margin: "auto auto 10px"
    },
    wrapper: {
        display: "flex",
    },
    bookingContainer: {
        marginTop: "20px",
        maxWidth: "590px",
        width: "75%",
        minWidth: "256px",
        '@media (max-width: 950px)': {
            width: "50%"
        },
        '@media (max-width: 490px)': {
            minWidth: "230px"
        },
    },
    bookingBtnContainer: {
        margin: "20px auto 0px",
        width: "25%"
    },
    bookingWrapper: {
        display: "flex",
        padding: "15px 0px 15px 15px",
        background: "#FFFFFF",
        border: "1px solid rgba(179, 179, 179, 0.18)",
        boxShadow: "0px 2px 40px rgba(186, 186, 186, 0.13)",
        borderRadius: "8px",
        gap: "15px",

    },
    imageContainer: {
        width: "85px",
        height: "85px",
    },
    profileSize: {
        width: "85px",
        borderRadius: "50%",
        height: "85px",
        "@media (max-width:1070px)": {
            width: "75px",
            height: "75px",
        },
    },
    bookingsInfoSection: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "flex-start",
        marginRight: "5px",
        '@media (max-width: 950px)': {
            display: "block !important",
        }
    },
    nameLabel: {
        fontSize: "20px",
        caretColor: "transparent",
        fontFamily: "SF Pro Text Bold",
        color: "#292929",
        overflow: "hidden" as "hidden",
        whiteSpace: "nowrap" as "nowrap",
        textOverflow: "ellipsis" as "ellipisis",
        width: "150px",
        marginTop: "15px",
        "@media (max-width:1070px)": {
            fontSize: "15px",
        },
    },
    healthDetails: {
        fontSize: "16px",
        caretColor: "transparent",
        fontFamily: "SF Pro Text Regular",
        color: "#3780E0",
        marginTop: "5px",
        marginRight: "30px",
        cursor:"pointer",
        '@media (max-width: 1070px)': {
            fontSize: "14px"
        },
        '@media (max-width: 950px)': {
            fontSize: "14px",
            textAlign: "left" as "left",
        }
    },
    time_duration: {
        fontSize: "18px",
        caretColor: "transparent",
        fontFamily: "SF Pro Text Medium",
        color: "#808080",
        marginTop: "15px",
        marginRight: "30px",
        textAlign: "right" as "right",
        '@media (max-width: 1070px)': {
            fontSize: "14px"
        },
        '@media (max-width: 950px)': {
            fontSize: "14px",
            textAlign: "left" as "left",
            marginTop: "0px",
        }
    },
    time: {
        fontSize: "18px",
        caretColor: "transparent",
        fontFamily: "SF Pro Text Medium",
        color: "#808080",
        marginTop: "5px",
        marginRight: "30px",
        textAlign: "right" as "right",
        '@media (max-width: 1070px)': {
            fontSize: "14px"
        },
        '@media (max-width: 950px)': {
            fontSize: "14px",
            textAlign: "left" as "left",
        }
    },
    statusBtn: {
        caretColor: "transparent",
        backgroundColor: "#30D158",
        color: "#FFFFFF",
        border: "none",
        borderRadius: "8px",
        width: "179px",
        height: "45px",
        cursor: "pointer",
        fontSize: "18px",
        fontFamily: "SF Pro Text Bold",
        marginTop: "33px",
        "&:hover": {
            backgroundColor: "#30D158",
            color: "#fff"
        },
        textTransform: "capitalize" as "capitalize",
        float: "right" as "right",
        '@media (max-width: 1250px)':
        {
            width: "125px"
        },
        '@media (max-width: 870px)': {
            fontSize: "14px",
            width: "100px"
        },
        '@media (max-width: 490px)': {
            fontSize: "13px",
            width: "90px"
        },
    },
    profileBookingHeading: {
        width: "100%",
        fontFamily: "SF Pro Text Bold",
        alignItems: "center",
        color: "#292929",
    },
    headerBookingContainer: {
        width: "100%",
        marginTop: "45px",
        marginLeft: "20px"
    },
    orderBookingHeading: {
        display: "flex",
        justifyContent: "flex-start",
        gap: "25px",
        alignItems: "center",
        marginBottom: "40px",
    },
    calendarBookingBackBtn: {
        display: "flex",
        padding: "13px 9px 13px 17px",
        justifyContent: "center",
        borderRadius: "8px",
        alignItems: "center",
        fontSize: "22px",
        border: "1px solid #E9E9E9",
    },
    noData: {
        fontSize: "20px",
        fontFamily: "SF Pro Text Medium",
        color: "#808080",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        margin: "60px auto"
    },
    noDataDashboardContainer: {
        display: "block",
        width: "362px",
        margin: "50px auto"
    },
    closeIcon: {
        display: "flex",
        margin: "auto"
    },
    dialogWrapperConatiner:{
        "&::-webkit-scrollbar": {
          display: "none" 
        },
        padding:"15px",
        scrollbarWidth: "thin" as "thin",
        overflowY: "scroll" as "scroll",
        scrollbarColor: "#d9d5d5 white"
    },
    hpTitleConatiner:{
        padding:"15px 30px",
        display:"flex",
        justifyContent:"space-between",
    },
    dialogHPTitle:{
        fontFamily:"SF Pro Text Bold",
        fontSize:"28px",
        color:"#292929",
    },  
    dialogContentSpace:{
        marginBottom:"15px"
    },
    healthDetailsLabel:{
        fontSize:"16px",
        padding:"5px",
        fontFamily:"SF Pro Text Medium",
        color:"#808080",
    },
    healthDetailsValue:{
        fontSize:"18px",
        padding:"5px",
        fontFamily:"SF Pro Text Medium",
        color:"#292929",
    },
}

export default withStyles(styles)(HealthcarePersonnelOrderManagement);
// Customizable Area End
