Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";
exports.healthcarePersonnelAvailabilitiesAPiEndPoint = "medic_availabilities";
exports.createCustomAvailability = "medic_availabilities/create_custom_availability";
exports.applyLeave = "medic_availabilities/create_leave_availability";
exports.updateCustomAvailability = "medic_availabilities";
exports.patchAPiMethod = "PATCH";
exports.deleteMethod = "DELETE";
exports.delteLeaveAPI = "/medic_availabilities/destroy_leave?id=";
exports.healthcarePersonnelAvailabilities = "/healthcare_personnel_availabilities";
exports.updateLeaveUrl = "healthcare_personnel_availabilities/edit_leave";
exports.healthcarePersonnelAvailabilitiesBulkEdit = "/healthcare_personnel_availabilities/bulk_edit";
exports.healthcarePersonnelAvailabilities = "/healthcare_personnel_availabilities";
// Customizable Area End