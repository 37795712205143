Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"
exports.getNotificationEndPoint = "/bx_block_notifications/notifications";
exports.InvalidUserMessage = "Please login as a valid user type";
exports.TokenHasExpired = 'Token has Expired';
exports.InvalidToken = "Invalid token";
exports.HospitalProfileEndPoint = "/profile_details";
exports.PatientProfileEndPoint = '/patient_profile_details';
exports.HPProfileEndPoint = '/healthcare_personnel_profile_details';
exports.HealthDetailsEndPoint = "hospital_order_management/health_details?bed_booking_id=";
exports.InsuranceListEndPoint = "hospital_order_management/view_insurance?bed_booking_id=";
// Customizable Area End