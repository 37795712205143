import * as React from "react";
// Customizable Area Start
import { withStyles, Box, Typography, Grid, ThemeProvider, createTheme, Stepper, Step, StepLabel, SvgIcon, TextField, MenuItem, Checkbox, FormControlLabel, Button, InputAdornment, ClickAwayListener, FormHelperText, Chip, Dialog } from "@material-ui/core";
import HpOrderManagementController, { Props } from "../HpOrderManagementController";
import Sidebar from "../../../../components/src/Sidebar.web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { AddBtn, DownArrow, HomecureLogo, Signature, SubtractBtn, UpwardArrow } from "./../assets";
import CloseIcon from '@material-ui/icons/Close';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

const SliderBlueCircle = () => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/SvgIcon">
    <circle cx="10" cy="10" r="9.5" fill="white" stroke="#4D6EFF" />
    <circle cx="10" cy="10" r="7" fill="#4D6EFF" />
  </SvgIcon>
);

const SliderGrayCircle = () => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/SvgIcon">
    <circle cx="10" cy="10" r="9.5" fill="#efefef" stroke="#adabab" />
    <circle cx="10" cy="10" r="7" fill="#efefef" />
  </SvgIcon>
);

const CustomsCheckboxIconChecked = () => (
  <SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
    <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </SvgIcon>
);

const CustomsCheckboxIconUnChecked = () => (
  <SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
  </SvgIcon>
);


// Customizable Area End

export class HealthcarePersonnelPrescription extends HpOrderManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getdiseasesList()
    this.getDiagnosisTestList()
    this.getHpDetailsList()
  }
  getIcon = (index: number) => (index <= this.state.activeStep ? <SliderBlueCircle /> : <SliderGrayCircle />);

  errorMessage = (error: string | undefined, inputError: any) => {
    return error ? (
      <FormHelperText className={inputError}>{error}</FormHelperText>
    ) : null;
  };

  diagnosisClass = (data: any) => { return this.state.selectedDiagnosis.includes(data) ? "SF Pro Text Medium" : "SF Pro Text Regular" }

  errorPrescriptionMessage = (classes: any, stateMessage: string, messageType: string) => {
    return (
      <Box>
        <Dialog open={stateMessage !== ""}>
          <Box className={classes.container}>
            <Box style={{ padding: "0px 10px" }}>
              <p className={classes.headingText}>{messageType}</p>
              <p className={classes.paragraphText}>{stateMessage}</p>
              <Button style={{
                borderRadius: "3px",
                backgroundColor: "rgb(33, 150, 243)",
                border: "none",
                color: "#fff",
                padding: "5px",
                marginBottom: "15px",
                marginTop: "10px",
                fontFamily: "SF Pro Text Regular"
              }} data-test-id="closePopup" onClick={() => this.closePopup(messageType)}>OK</Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
    )
  }

  doctorData = this.state.role === 'doctor' ? 'Dr.' : this.state.selectedTitle;

  signatureUrl = () => { return this.state.signatureImage || Signature };

  btnContainer = (classes: any) => {
    return (
      <Box style={{ marginTop: "5%" }}>
        {this.state.activeStep === 3 ? (
          <Button className={classes.submitBtn} data-test-id="submit" onClick={this.callPrescriptionAPI}>Submit</Button>
        ) : (
          <Button className={classes.nextBtn} data-test-id="moveToNextPage" onClick={this.showPrescriptionForm}>Next</Button>
        )}
      </Box>)
  }

  checkedToBeTakenHandel = (index: number, time: string) => { return this.state.prescribed_medications_attributes[index]?.to_be_taken?.includes(time) || false }

  checkedTImeOfDayHandel = (index: number, time: string) => { return this.state.prescribed_medications_attributes[index]?.time_of_the_day?.includes(time) || false }

  handelDiseases = (classes: any) => {
    return (
      <>
        {!this.state.hideDiseasesShowDropDown ? (
          <>
            <TextField
              variant="outlined"
              className={classes.inputChiefFields}
              placeholder={this.state.selectedComplaints.length > 0 ? "" : "Mention here"}
              data-test-id="openDiseasesDropdown"
              onClick={this.hideShowDiseasesDropDown}
              InputProps={{
                startAdornment: (
                  <Box
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "4px",
                      maxHeight: "90px",
                      overflow: "auto",
                      alignItems: "center",
                      scrollbarColor: "transparent transparent",
                      marginTop: "5px",
                      marginBottom: "5px",
                      width: this.state.hideDiseasesShowDropDown ? "100%" : ""
                    }}
                  >
                    {this.state.diseasesList
                      .filter((item: any) => this.state.selectedComplaints.includes(item.name))
                      .map((item: any, index: number) => (
                        <Chip
                          key={index}
                          label={item.name}
                          data-test-id={`remove${index}`}
                          onDelete={() => this.handleChipDelete(item.name)}
                          size="small"
                          className={classes.chip}
                          deleteIcon={<CloseIcon fontSize="small" />}
                        />
                      ))}
                  </Box>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={DownArrow}
                      alt="DownArrow"
                      className={classes.hpDownArrowIcon}
                    />
                  </InputAdornment>
                ),
                readOnly: true,
              }}
            />
          </>
        ) : (
          <Box className={classes.hpSelectFieldDropdown}>
            <Box className={classes.hpDropdownContent}>
              <Box className={classes.dropdownText}>
                Select
                <img
                  data-test-id="hideDiseasescDropdown"
                  src={UpwardArrow}
                  alt="UpwardArrow"
                  className={classes.hpUpArrowIcon}
                  onClick={this.closeDiseasesDropdown}
                />
              </Box>
              <Box className={classes.hpSelectFieldSize}>
                {this.state.diseasesList.map((data: any, index: number) => (
                  <Box
                    className={classes.hpSelectOption}
                    key={index}
                    style={{
                      fontFamily: this.state.selectedComplaints.includes(data.name)
                        ? "SF Pro Text Medium"
                        : "SF Pro Text Regular",
                    }}
                  >
                    <Checkbox
                      data-test-id={`selectedComplaints${index}`}
                      className={classes.checkbox}
                      checked={this.state.selectedComplaints.includes(data.name)}
                      onChange={() => this.handleDiseasesCheckboxChange(data.name)}
                      icon={<CustomsCheckboxIconUnChecked />}
                      checkedIcon={<CustomsCheckboxIconChecked />}
                    />
                    {data.name}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        )}
      </>
    )
  }

  handelRepeatTime = (index: number, classes: any) => {
    return (
      <>
        {this.state.isDropdown &&
          (<Box
            className={classes.customDropdown}
            key={index}
            style={{
              position: 'absolute',
              borderRadius: "8px",
              zIndex: 10,
              border: '1px solid #ccc',
              marginTop: '5px',
              backgroundColor: '#fff',
              width: '45px',
              padding: "2px",
              fontFamily: "SF Pro Text Regular",
              maxHeight: "200px",
              overflow: "scroll",
              scrollbarColor: "#d9d5d5 #fff",
              scrollbarWidth: "thin",
              textAlign: "center"

            }}
          >
            {Array.from({ length: 366 }, (_, i) => (
              <Box
                key={i + 1}
                onClick={() => {
                  this.handleDaysTypeChange(i + 1)
                }}
                data-test-id={`selectDay${index}${i}`}
                className={classes.selectDay}
                style={{ textTransform: "capitalize" }}
              >
                {i + 1}
              </Box>
            ))}
          </Box>
          )}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <Grid container className={classes.leftHPWrapper}>
            <Grid item xs={1}>
              <Sidebar />
            </Grid>
            <Grid item xs={11} className={classes.righHPtWrapper}>
              <Box>
                <Box className={classes.headerHPContainer}>
                  <Box className={classes.orderHeading}>
                    <ArrowBackIosIcon
                      className={classes.calendarHPBackBtn}
                      data-test-id="backToPrescriptionScreen"
                      onClick={this.backToHpOrderManagementScreen}
                    />
                    <Typography variant="h4" className={classes.profileHPHeading}>Prescription</Typography>
                  </Box>
                </Box>
                <Box className={classes.stepperContainer}>
                  <Stepper activeStep={this.state.activeStep} alternativeLabel classes={{ root: classes.stepperClasses }}>
                    {this.state.steps.map((step: any, index: number) => (
                      <Step key={index}>
                        <StepLabel StepIconComponent={() => this.getIcon(index)}>{step.label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
                {this.state.activeStep === 0 &&
                  (<Grid container >
                    <Grid item xl={8} md={8} sm={12} xs={12}>
                      <Box className={classes.inputContainer}>
                        <Typography variant="h4" className={classes.homeCureHeading}>HomeCure</Typography>
                        <Box className={classes.seperationLine} />
                        <Box style={{ width: "100%" }}>
                          <Box>
                            <TextField
                              variant="outlined"
                              data-test-id="hospitalName"
                              placeholder="Hospital Name"
                              className={classes.inputNameFields}
                              value={this.state.hospitalName}
                            />
                          </Box>
                        </Box>
                        <Box style={{ width: "100%" }}>
                          <Box>
                            <TextField
                              variant="outlined"
                              data-test-id="city"
                              placeholder="City"
                              className={classes.inputCityFields}
                              value={this.state.city}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Grid container spacing={5}>
                        <Grid item xl={6} md={6} sm={12} xs={12}>
                          <Box className={classes.containerSpace}>
                            <Typography variant="h4" className={classes.homecureLabel}>Heathcare Personnel Name</Typography>
                            <Box style={{ display: "flex" }}>
                              <TextField
                                variant="outlined"
                                select
                                placeholder="Title"
                                value={this.doctorData}
                                data-test-id="selectTitle"
                                InputProps={{ readOnly: this.state.role === 'doctor' }}
                                className={classes.inputTitleFields}
                                onChange={event => this.handlePrescriptionChanges("selectedTitle", event.target.value)}
                              >
                                {this.state.title.map((option: any) => (
                                  <MenuItem className={classes.menuItem} key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <TextField
                                variant="outlined"
                                placeholder="Name"
                                data-test-id="hpName"
                                className={classes.inputTitleNameFields}
                                value={this.state.hpName}
                              />
                            </Box>
                          </Box>
                          <Box className={classes.containerSpace}>
                            <Box style={{ display: "flex", gap: "5%" }}>
                              <Box>
                                <Typography variant="h4" className={classes.homecureLabel}>Date <span style={{ color: "red" }}>*</span></Typography>
                                <TextField
                                  variant="outlined"
                                  placeholder="DD-MM-YYYY"
                                  className={classes.inputDegreeNameFields}
                                  data-test-id="date"
                                  onChange={event => this.handlePrescriptionChanges("date", event.target.value.trimStart())}
                                  value={this.state.date}
                                />
                                {this.errorMessage(this.state.errors.date, classes.inputErrors)}
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.homecureLabel}>Time <span style={{ color: "red" }}>*</span></Typography>
                                <TextField
                                  variant="outlined"
                                  placeholder="HH:MM"
                                  className={classes.inputDegreeNameFields}
                                  data-test-id="time"
                                  onChange={event => this.handlePrescriptionChanges("time", event.target.value.trimStart())}
                                  value={this.state.time}
                                />
                                {this.errorMessage(this.state.errors.time, classes.inputErrors)}
                              </Box>
                            </Box>
                          </Box>
                          <Box className={classes.containerSpace}>
                            <Typography variant="h4" className={classes.homecureLabel}>Consult Type</Typography>
                            <Box style={{ display: "flex", gap: "5%" }}>
                              <FormControlLabel
                                className={classes.consultType}
                                data-test-id="video_call"
                                control={<Checkbox checked={this.state.consultType === "online"} className={classes.checkboxWhite} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                                label={"Video Call"}
                                onClick={() => this.setConsultType("online")}
                              />
                              <FormControlLabel
                                className={classes.consultType}
                                data-test-id="home_visit"
                                control={<Checkbox checked={this.state.consultType === "In-person"} className={classes.checkboxWhite} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                                label={"Home Visit"}
                                onClick={() => this.setConsultType("In-person")}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xl={6} md={6} sm={12} xs={12}>
                          <Box className={classes.containerSpace}>
                            <Typography variant="h4" className={classes.homecureLabel}>Degree Name</Typography>
                            <TextField
                              variant="outlined"
                              placeholder="Degree Name"
                              className={classes.degreeNameFields}
                              data-test-id="degree"
                              value={this.state.degree}
                            />
                          </Box>
                          <Box className={classes.containerSpace}>
                            <Typography variant="h4" className={classes.homecureLabel}>Consult ID <span style={{ color: "red" }}>*</span></Typography>
                            <TextField
                              variant="outlined"
                              placeholder="ID"
                              className={classes.inputDegreeNameFields}
                              data-test-id="consultId"
                              value={this.state.consultId}
                              onChange={event => this.handlePrescriptionChanges("consultId", event.target.value.trimStart())}
                            />
                            {this.errorMessage(this.state.errors.consultId, classes.inputErrors)}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>)}
                {this.state.activeStep === 1 &&
                  (
                    <Box>
                      <Grid container >
                        <Grid item xl={8} md={8} sm={12} xs={12}>
                          <Grid container spacing={5}>
                            <Grid item xl={6} md={6} sm={12} xs={12}>
                              <Box className={classes.containerSpace}>
                                <Typography variant="h4" className={classes.homecureLabel}>Patient Name <span style={{ color: "red" }}>*</span></Typography>
                                <TextField
                                  variant="outlined"
                                  placeholder="Name"
                                  className={classes.inputDegreeNameFields}
                                  data-test-id="patientName"
                                  onChange={(event: any) => this.handlePrescriptionChanges("patientName", event.target.value.trimStart())}
                                  value={this.state.patientName}
                                />
                                {this.errorMessage(this.state.errors.patientName, classes.inputErrors)}
                              </Box>
                            </Grid>
                            <Grid item xl={6} md={6} sm={12} xs={12}>
                              <Box className={classes.containerSpace}>
                                <Box style={{ display: "flex", gap: "5%" }}>
                                  <Box style={{ width: "50%" }}>
                                    <Typography variant="h4" className={classes.homecureLabel}>Age <span style={{ color: "red" }}>*</span></Typography>
                                    <TextField
                                      variant="outlined"
                                      placeholder="Enter age"
                                      className={classes.inputDegreeNameFields}
                                      data-test-id="age"
                                      onChange={event => this.handlePrescriptionChanges("age", event.target.value.trimStart())}
                                      value={this.state.age}
                                    />
                                    {this.errorMessage(this.state.errors.age, classes.inputErrors)}
                                  </Box>
                                  <Box style={{ width: "50%" }}>
                                    <Typography variant="h4" className={classes.homecureLabel}>Gender <span style={{ color: "red" }}>*</span></Typography>
                                    <Box>
                                      <TextField
                                        variant="outlined"
                                        placeholder="Select"
                                        className={classes.inputDegreeNameFields}
                                        value={this.state.gender}
                                        onClick={this.toggleDropdown}
                                        data-test-id="gender"
                                      />
                                      {this.state.isDropdownOpen && (
                                        <Box
                                          className={this.props.classes.customDropdown}
                                          style={{
                                            position: 'absolute',
                                            border: '1px solid #ccc',
                                            marginTop: '5px',
                                            backgroundColor: '#fff',
                                            width: '150px',
                                            borderRadius: "8px",
                                            zIndex: 10,
                                            padding: "2px",
                                            fontFamily: "SF Pro Text Regular"
                                          }}
                                        >
                                          {this.state.gendeOption.map((option: any, index: number) => (
                                            <Box
                                              key={option.value}
                                              data-test-id={`gender${index}`}
                                              onClick={() => this.handlePrescriptionChanges('gender', option.value)}
                                              className={classes.selectItem}
                                            >
                                              {option.label}
                                            </Box>
                                          ))}
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xl={8} md={8} sm={12} xs={12}>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel}>Vitals</Typography>
                          </Box>
                          <Grid container className={classes.vitalContainer}>
                            <Grid item xl={6} md={6} sm={12} xs={12} className={classes.rightContainer}>
                              <Box>
                                <Typography variant="h4" className={classes.homecureBoldVitalLabel}>Blood Pressure</Typography>
                                <TextField
                                  variant="filled"
                                  placeholder="xxx/yyy"
                                  className={classes.inputVitalFields}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <div style={{ fontFamily: "SF Pro Text Regular", fontSize: "14px", color: "#808080", marginTop: "14px" }}> mmHg</div>
                                      </InputAdornment>
                                    )
                                  }}
                                  data-test-id="bloodPressure"
                                  onChange={event => this.handlePrescriptionChanges("bloodPressure", event.target.value.trimStart())}
                                  value={this.state.bloodPressure}
                                />
                                <FormControlLabel
                                  className={classes.declaredBy}
                                  control={<Checkbox checked={this.state.bloodPressureDecared} className={classes.checkboxWhite} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                                  label={"Declared by patient"}
                                  data-test-id="bloodPressureDecared"
                                  onClick={() => this.handelDeclaredBy("bloodPressureDecared", !this.state.bloodPressureDecared)}
                                />
                                {this.errorMessage(this.state.errors.bloodPressure, classes.inputErrors)}
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.homecureBoldVitalLabel}>Weight</Typography>
                                <Box style={{ display: "flex" }}>
                                  <TextField
                                    placeholder="xxx"
                                    className={classes.inputVitalFields}
                                    variant="filled"
                                    data-test-id="weight"
                                    InputProps={{ endAdornment: (<InputAdornment position="end"><div style={{ fontFamily: "SF Pro Text Regular", fontSize: "14px", color: "#808080", marginTop: "14px" }}>kg</div></InputAdornment>) }}
                                    onChange={event => this.handlePrescriptionChanges("weight", event.target.value.trimStart())}
                                    value={this.state.weight}
                                  />
                                </Box>
                                <FormControlLabel
                                  className={classes.declaredBy}
                                  control={<Checkbox checked={this.state.weightDeclared} className={classes.checkboxWhite} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                                  label={"Declared by patient"}
                                  data-test-id="weightDeclared"
                                  onClick={() => this.handelDeclaredBy("weightDeclared", !this.state.weightDeclared)}
                                />
                                {this.errorMessage(this.state.errors.weight, classes.inputErrors)}
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.homecureBoldVitalLabel}>Temperature</Typography>
                                <TextField
                                  variant="filled"
                                  placeholder="xxx"
                                  data-test-id="temperature"
                                  className={classes.inputVitalFields}
                                  InputProps={{ endAdornment: (<InputAdornment position="end"><div style={{ fontFamily: "SF Pro Text Regular", fontSize: "14px", color: "#808080", marginTop: "14px" }}>celcius</div></InputAdornment>) }}
                                  onChange={event => this.handlePrescriptionChanges("temperature", event.target.value.trimStart())}
                                  value={this.state.temperature}
                                />
                                <FormControlLabel
                                  className={classes.declaredBy}
                                  control={<Checkbox checked={this.state.temperatureDeclared} className={classes.checkboxWhite} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                                  label={"Declared by patient"}
                                  data-test-id="temperatureDeclared"
                                  onClick={() => this.handelDeclaredBy("temperatureDeclared", !this.state.temperatureDeclared)}
                                />
                                {this.errorMessage(this.state.errors.temperature, classes.inputErrors)}
                              </Box>
                            </Grid>
                            <Grid item xl={6} md={6} sm={12} xs={12} className={classes.leftContainer}>
                              <Box>
                                <Typography variant="h4" className={classes.homecureBoldVitalLabel}>Height</Typography>
                                <TextField
                                  variant="filled"
                                  className={classes.inputVitalFields}
                                  placeholder="xxx"
                                  data-test-id="height"
                                  InputProps={{ endAdornment: (<InputAdornment position="end"><div style={{ fontFamily: "SF Pro Text Regular", fontSize: "14px", color: "#808080", marginTop: "14px" }}>cm</div></InputAdornment>) }}
                                  onChange={event => this.handlePrescriptionChanges("height", event.target.value.trimStart())}
                                  value={this.state.height}
                                />
                                <FormControlLabel
                                  className={classes.declaredBy}
                                  control={<Checkbox checked={this.state.heightDeclared} className={classes.checkboxWhite} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                                  label={"Declared by patient"}
                                  data-test-id="heightDeclared"
                                  onClick={() => this.handelDeclaredBy("heightDeclared", !this.state.heightDeclared)}
                                />
                                {this.errorMessage(this.state.errors.height, classes.inputErrors)}
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.homecureBoldVitalLabel}>Pulse</Typography>
                                <TextField
                                  placeholder="xxx"
                                  variant="filled"
                                  className={classes.inputVitalFields}
                                  data-test-id="pulse"
                                  InputProps={{ endAdornment: (<InputAdornment position="end"><div style={{ fontFamily: "SF Pro Text Regular", fontSize: "14px", color: "#808080", marginTop: "14px" }}>/ minute</div></InputAdornment>) }}
                                  onChange={event => this.handlePrescriptionChanges("pulse", event.target.value.trimStart())}
                                  value={this.state.pulse}
                                />
                                <FormControlLabel
                                  className={classes.declaredBy}
                                  control={<Checkbox checked={this.state.pulseDeclared} className={classes.checkboxWhite} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                                  label={"Declared by patient"}
                                  data-test-id="pulseDeclared"
                                  onClick={() => this.handelDeclaredBy("pulseDeclared", !this.state.pulseDeclared)}
                                />
                                {this.errorMessage(this.state.errors.pulse, classes.inputErrors)}
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xl={4} md={4} sm={12} xs={12}>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel}>Chief Complaints <span style={{ color: "red" }}>*</span></Typography>
                            <Grid container className={classes.chiefContainer}>
                              <Grid item xl={12} md={12} sm={12} xs={12}>
                                <ClickAwayListener data-test-id="diseasesDropdown" onClickAway={this.closeDiseasesDropdown}>
                                  <Box>
                                    <Box className={classes.hpSelectCheckbox}>
                                      {this.handelDiseases(classes)}
                                    </Box>
                                  </Box>
                                </ClickAwayListener>
                              </Grid>
                            </Grid>
                            {this.errorMessage(this.state.errors.selectedComplaints, classes.inputErrors)}
                          </Box>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel} style={{ marginTop: "20px" }}>Details</Typography>
                            <Grid container className={classes.detailContainer}>
                              <Grid item xl={12} md={12} sm={12} xs={12}>
                                <Box>
                                  {this.state.detail.map((value: string, index: number) => (
                                    <Box key={index}>
                                      <Typography variant="h4" className={classes.homecureDetailsLabel}>
                                        Line {index + 1}
                                      </Typography>
                                      <TextField
                                        variant="filled"
                                        placeholder="Mention here"
                                        className={classes.inputDetailsFields}
                                        data-test-id={`detail${index + 1}`}
                                        onChange={(event) => this.handleDetailsChange(index, event.target.value.trimStart())}
                                        value={value}
                                      />
                                    </Box>
                                  ))}
                                </Box>
                                <Box className={classes.addBtn} onClick={this.handleAddDetails}>
                                  + Add
                                </Box>
                                <Box>
                                  <Typography variant="h4" className={classes.message}>(Can add as many as 10 lines on each click of + Add button)</Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                {this.state.activeStep === 2 &&
                  (
                    <Box>
                      <Grid container >
                        <Grid item xl={4} md={4} sm={12} xs={12}>
                          <Box>
                            <Box>
                              <Typography variant="h4" className={classes.diagnosisTitle}>Diagonosis <span style={{ color: "red" }}>*</span></Typography>
                            </Box>
                            <Grid container className={classes.detailContainer}>
                              <Grid item xl={12} md={12} sm={12} xs={12}>
                                <Box>
                                  {this.state.diagnosis.map((value: string, index: number) => (
                                    <Box key={index}>
                                      <Typography variant="h4" className={classes.homecureDetailsLabel}>
                                        Line {index + 1}
                                      </Typography>
                                      <TextField
                                        variant="filled"
                                        placeholder="Mention here"
                                        className={classes.inputDetailsFields}
                                        data-test-id={`line${index + 1}`}
                                        onChange={(event) => this.handleDiagnosisChange(index, event.target.value.trimStart())}
                                        value={value}
                                      />
                                    </Box>
                                  ))}
                                </Box>
                                <Box className={classes.addBtn} data-test-id="addmore" onClick={this.handleAddDiagnosis}>
                                  + Add
                                </Box>
                                {this.errorMessage(this.state.errors.diagnosis, classes.inputErrors)}
                                <Box>
                                  <Typography variant="h4" className={classes.message}>(Can add as many as 10 lines on each click of + Add button)</Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box>
                            <Box>
                              <Typography variant="h4" className={classes.diagnosisTest}>Diagonstic Test</Typography>
                            </Box>
                            <Grid container>
                              <Grid item xl={12} md={12} sm={12} xs={12}>
                                <ClickAwayListener data-test-id="diagolosticDropdown" onClickAway={this.hidwDropDown}>
                                  <Box>
                                    <Box className={classes.hpSelectCheckbox}>
                                      <>
                                        {!this.state.hideShowDropDown ? (
                                          <>
                                            <TextField
                                              variant="outlined"
                                              className={classes.hpRegisterInputField}
                                              placeholder={this.state.selectedDiagnosis.length > 0 ? "" : "Select"}
                                              data-test-id="openDiagolosticDropdown"
                                              onClick={this.hideShowDropDown}
                                              value={this.handleSelectedNames(this.state.diagnostic)}
                                              InputProps={{
                                                startAdornment: (
                                                  <Box
                                                    style={{
                                                      display: "flex",
                                                      flexWrap: "wrap",
                                                      gap: "4px",
                                                      maxHeight: "90px",
                                                      overflow: "auto",
                                                      alignItems: "center",
                                                      scrollbarColor: "transparent transparent",
                                                      marginTop: "5px",
                                                      marginBottom: "5px",
                                                      width: this.state.hideShowDropDown ? "100%" : ""
                                                    }}
                                                  >
                                                    {this.state.diagnostic
                                                      .filter((item: any) => this.state.selectedDiagnosis.includes(item))
                                                      .map((item: any, index: number) => (
                                                        <Chip
                                                          key={index}
                                                          label={item}
                                                          data-test-id={`diagnosticRemove${index}`}
                                                          onDelete={() => this.handleChipDelete(item)}
                                                          size="small"
                                                          className={classes.chip}
                                                          deleteIcon={<CloseIcon fontSize="small" />}
                                                        />
                                                      ))}
                                                  </Box>
                                                ),
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    <img
                                                      src={DownArrow}
                                                      alt="DownArrow"
                                                      className={classes.hpDownArrowIcon}
                                                    />
                                                  </InputAdornment>
                                                ),
                                                readOnly: true,
                                              }}
                                            />
                                          </>
                                        ) : (
                                          <Box className={classes.hpSelectFieldDropdown}>
                                            <Box className={classes.hpDropdownContent}>
                                              <Box className={classes.dropdownText}>
                                                Select
                                                <img
                                                  data-test-id="hideDiagolosticDropdown"
                                                  src={UpwardArrow}
                                                  alt="UpwardArrow"
                                                  className={classes.hpUpArrowIcon}
                                                  onClick={this.hidwDropDown}
                                                />
                                              </Box>
                                              <Box className={classes.hpSelectFieldSize}>
                                                {this.state.diagnostic.length > 0 &&
                                                  this.state.diagnostic.map((data: string, index: number) => (
                                                    <Box
                                                      className={classes.hpSelectOption}
                                                      key={index}
                                                      style={{
                                                        fontFamily: this.diagnosisClass(data)
                                                      }}
                                                    >
                                                      <Checkbox
                                                        data-test-id={`selectedDiagnosis${index}`}
                                                        className={classes.checkbox}
                                                        checked={this.state.selectedDiagnosis.includes(data)}
                                                        onChange={() => this.handleCheckboxChange(data)}
                                                        icon={<CustomsCheckboxIconUnChecked />}
                                                        checkedIcon={<CustomsCheckboxIconChecked />}
                                                      />
                                                      {data}
                                                    </Box>
                                                  ))}
                                              </Box>
                                            </Box>
                                          </Box>
                                        )}
                                      </>
                                    </Box>
                                  </Box>
                                </ClickAwayListener>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                {this.state.activeStep === 3 && (
                  <Grid container spacing={4}>
                    <Grid item xl={5} md={5} sm={12} xs={12}>
                      <Box>
                        <Typography variant="h4" className={classes.medicinesTitle}>Medicines</Typography>
                      </Box>
                      <Box>
                        {this.state.prescribed_medications_attributes.map((prescription: any, index: number) => (
                          <>
                            <Box style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
                              {this.state.medicineOptions.map((option: any) => (
                                <Box key={option.id} className={classes.vitalBox}>
                                  <Box>
                                    <Checkbox
                                      data-test-id={`${option.id}Checkbox`}
                                      checked={prescription.medication_type === option.label}
                                      className={classes.checkbox}
                                      color="primary"
                                      icon={<CustomsCheckboxIconUnChecked />}
                                      checkedIcon={<CustomsCheckboxIconChecked />}
                                      onChange={() => this.handlePrescriptionChange(index, "medication_type", option.label)}
                                    />
                                  </Box>
                                  <Box>
                                    <Typography variant="h4" className={classes.presDetails}>
                                      {option.label}
                                    </Typography>
                                  </Box>
                                </Box>
                              ))}
                            </Box>
                            <Grid container key={index} className={classes.detailContainer}>
                              <Grid item xl={12} md={12} sm={12} xs={12}>
                                <Box>
                                  <Typography variant="h4" className={classes.medicicneNameTitle}>
                                    Name of the medicine
                                  </Typography>
                                  <TextField
                                    variant="outlined"
                                    placeholder="Type here"
                                    className={classes.inputDegreeNameFields}
                                    value={prescription.name}
                                    data-test-id="medicineName"
                                    onChange={(e) => this.handlePrescriptionChange(index, "name", e.target.value.trimStart())}
                                  />
                                  {this.errorMessage(this.state.errors.medicineName, classes.inputErrors)}
                                </Box>
                                <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                                  <Box style={{width:"60%"}}>
                                    <Typography variant="h4" className={classes.doseDurationTitle}>
                                      Dosage
                                    </Typography>
                                    <Box style={{ display: "flex" }}>
                                      <Box className={classes.subBtnContainer}
                                        data-test-id="dosageDecrement"
                                        onClick={() =>
                                          this.handlePrescriptionChange(
                                            index,
                                            "dosage",
                                            parseInt(prescription.dosage) - 1
                                          )
                                        }>
                                        <img
                                          src={SubtractBtn}
                                          style={{ width: "8px" }}
                                        />
                                      </Box>
                                      <Typography variant="h4" className={classes.tabletText}>
                                        {prescription.dosage} tablet
                                      </Typography>
                                      <Box className={classes.addBtnContainer}
                                        data-test-id="dosageIncrement"
                                        onClick={() =>
                                          this.handlePrescriptionChange(
                                            index,
                                            "dosage",
                                            parseInt(prescription.dosage) + 1
                                          )
                                        }>
                                        <img
                                          src={AddBtn}
                                          style={{ width: "9px", height: "9px" }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box style={{width:"40%"}}>
                                    <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                      <Box style={{ display: "flex", justifyContent: 'space-between' }}>
                                        <Typography variant="h4" className={classes.doseDurationTitle}>
                                          Duration
                                        </Typography>
                                        <Box key={index}>
                                          <TextField
                                            variant="outlined"
                                            placeholder="duration"
                                            className={classes.inputDurationFields}
                                            value={this.state.durationName}
                                            onClick={this.toggleDropdown}
                                            data-test-id={`duration${index}`}
                                            InputProps={{
                                              endAdornment: (<InputAdornment position="end">
                                                <img
                                                  src={DownArrow}
                                                  style={{ width: "10px", height: "5px" }}
                                                />
                                              </InputAdornment>)
                                            }}
                                          />
                                          {this.state.isDropdownOpen &&
                                            (<Box
                                              className={this.props.classes.customDropdown}
                                              key={index}
                                              style={{
                                                position: 'absolute',
                                                borderRadius: "8px",
                                                zIndex: 10,
                                                border: '1px solid #ccc',
                                                marginTop: '5px',
                                                backgroundColor: '#fff',
                                                width: '150px',
                                                padding: "2px",
                                                fontFamily: "SF Pro Text Regular",
                                              }}
                                            >
                                              {this.state.durations.map((option: any, ind: number) => (
                                                <Box
                                                  key={option.value}
                                                  data-test-id={`durationName${ind}`}
                                                  onClick={() => {
                                                    this.handlePrescriptionChange(index, 'duration', option.value);
                                                  }}
                                                  className={classes.selectItem}
                                                  style={{ textTransform: "capitalize" }}
                                                >
                                                  {option.label}
                                                </Box>
                                              ))}
                                            </Box>
                                            )}
                                        </Box>
                                      </Box>
                                      <Box style={{ display: "flex", alignItems: "center", gap: "15px", height: "10px" }}>
                                        <Box style={{ display: "flex", alignItems: "center" }}>
                                          <Box className={classes.subBtnDurationContainer}
                                            data-test-id="decrementDurationCount"
                                            onClick={() =>
                                              this.handlePrescriptionChange(
                                                index,
                                                "durationCount",
                                                prescription.durationCount - 1
                                              )
                                            }>
                                            <img
                                              src={SubtractBtn}
                                              style={{ width: "8px" }}
                                            />
                                          </Box>
                                          <Typography variant="h4" className={classes.tabletText} style={{ textTransform: "lowercase" }}>
                                            {prescription.duration}
                                          </Typography>
                                          <Box className={classes.addDurationBtnContainer}
                                            data-test-id="incrementDurationCount"
                                            onClick={() =>
                                              this.handlePrescriptionChange(
                                                index,
                                                "durationCount",
                                                prescription.durationCount + 1
                                              )
                                            }>
                                            <img
                                              src={AddBtn}
                                              style={{ width: "9px", height: "9px" }}
                                            />
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box>
                                  <Typography variant="h4" className={classes.doseTimeDurationTitle}>
                                    Repeat
                                  </Typography>
                                </Box>
                                <Box style={{ display: "flex" }}>
                                  {['Everyday', this.state.everyDay].map((repeatType) => (
                                    <Box key={repeatType} style={{ display: "flex", marginRight: "20px" }}>
                                      <Checkbox
                                        checked={this.state.prescribed_medications_attributes[index].repeat === repeatType}
                                        data-test-id={`Repeat${repeatType}`}
                                        className={classes.checkbox}
                                        color="primary"
                                        icon={<CustomsCheckboxIconUnChecked />}
                                        checkedIcon={<CustomsCheckboxIconChecked />}
                                        onClick={() => this.handleRepeatChange(index, repeatType)}
                                      />
                                      {repeatType === "Everyday" ?
                                        (
                                          <Typography variant="h4" className={classes.timeDurationText}>
                                            {repeatType}
                                          </Typography>) : (
                                          <Box style={{ display: "flex" }}>
                                            <Typography variant="h4" className={classes.timeDurationText}>
                                              Everyday
                                            </Typography>
                                            <Box>
                                              <Typography className={classes.inputRepeatFields} data-test-id={`day${index}`} onClick={this.toggleRepeatDropdown}>{this.state.day}</Typography>
                                              {this.handelRepeatTime(index,classes)}
                                            </Box>
                                            <Typography variant="h4" className={classes.timeDurationText}>
                                              days
                                            </Typography>
                                          </Box>
                                        )}
                                    </Box>
                                  ))}
                                </Box>
                                <Box>
                                  <Typography variant="h4" className={classes.doseTimeDurationTitle}>
                                    Time of the day
                                  </Typography>
                                </Box>
                                <Box style={{ display: "flex", flexWrap: "wrap" }}>
                                  {['Morning', 'Noon', 'Evening', 'Night'].map((time) => (
                                    <Box key={time} style={{ display: "flex", marginRight: "20px" }}>
                                      <Checkbox
                                        checked={this.checkedTImeOfDayHandel(index, time)}
                                        data-test-id={`${time}Time`}
                                        className={classes.checkbox}
                                        color="primary"
                                        icon={<CustomsCheckboxIconUnChecked />}
                                        checkedIcon={<CustomsCheckboxIconChecked />}
                                        onClick={() => this.handleSetDayTypeCheckbox(index, time)}
                                      />
                                      <Typography variant="h4" className={classes.timeDurationText}>
                                        {time}
                                      </Typography>
                                    </Box>
                                  ))}
                                </Box>
                                <Box>
                                  <Typography variant="h4" className={classes.doseTimeDurationTitle}>
                                    To be taken
                                  </Typography>
                                </Box>
                                <Box style={{ display: "flex", flexWrap: "wrap" }}>
                                  {['Before Food', 'After Food', 'Before Sleep'].map((time) => (
                                    <Box key={time} style={{ display: "flex", marginRight: "15px" }}>
                                      <Checkbox
                                        checked={this.checkedToBeTakenHandel(index, time)
                                        }
                                        data-test-id={`${time}toBeTaken`}
                                        className={classes.checkbox}
                                        color="primary"
                                        icon={<CustomsCheckboxIconUnChecked />}
                                        checkedIcon={<CustomsCheckboxIconChecked />}
                                        onClick={() => this.handlemedicineTakenTimeCheckbox(index, time)}
                                      />
                                      <Typography variant="h4" className={classes.timeDurationText}>
                                        {time}
                                      </Typography>
                                    </Box>
                                  ))}
                                </Box>
                              </Grid>
                            </Grid>
                          </>
                        ))}
                        <Box className={classes.addBtn} data-test-id="addMoveMedecineDetails" onClick={this.handleAddMore}>
                          + Add More
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xl={5} md={5} sm={12} xs={12}>
                      <Box>
                        <Typography variant="h4" style={{ margin: "50px auto 12px", fontSize: "18px", fontFamily: "SF Pro Text Medium", color: "#292929" }}>General Advices</Typography>
                        <Grid container className={classes.detailContainer}>
                          <Grid item xl={12} md={12} sm={12} xs={12}>
                            <Box>
                              {this.state.generalAdvice.map((value: string, index: number) => (
                                <Box key={index}>
                                  <Typography variant="h4" data-test-id="adviceLine" className={classes.homecureDetailsLabel}>Line {index + 1}</Typography>
                                  <TextField
                                    variant="filled"
                                    placeholder="Mention here"
                                    className={classes.inputDetailsFields}
                                    data-test-id={`adviceLine${index}`}
                                    onChange={(event) => this.handleGeneralAdviceChange(index, event.target.value.trimStart())}
                                    value={value}
                                  />
                                </Box>
                              ))}
                            </Box>
                            <Box className={classes.addBtn} onClick={this.handleAddGeneralAdvice}>
                              + Add
                            </Box>
                          </Grid>
                        </Grid>
                        <Box>
                          <Typography variant="h4" className={classes.addMoreContainer}>(Can add as many as 50 lines on each click of + Add button)</Typography>
                        </Box>
                        <Grid container className={classes.signatureContainer}>
                          <Grid item xl={12} md={12} sm={12} xs={12}>
                            <img
                              src={this.signatureUrl()}
                              alt="Signature"
                              data-test-id="signature"
                              style={{ display: 'flex', margin: 'auto', cursor: 'pointer', maxHeight: "100%", maxWidth: "100%" }}
                              onClick={() => this.fileInputRef.current.click()}
                            />
                          </Grid>
                          <input
                            type="file"
                            data-test-id="uploadSignature"
                            accept=".jpg, .jpeg, .png, .gif"
                            ref={this.fileInputRef}
                            style={{ display: 'none' }}
                            onChange={this.handleUploadSignature}
                          />
                        </Grid>
                      </Box>
                      <Box>
                        <Typography variant="h4" className={classes.signatureText}>Signature</Typography>
                      </Box>
                      <Box>
                        <img src={HomecureLogo} style={{ display: "flex", margin: '10px auto' }} />
                      </Box>
                    </Grid>
                  </Grid>
                )}
                {this.btnContainer(classes)}
              </Box>
            </Grid>
          </Grid>
          {this.errorPrescriptionMessage(classes, this.state.message, this.state.messageType)}
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  leftHPWrapper: {
    overflowX: "hidden" as "hidden",
    height: "100%",
  },
  righHPtWrapper: {
    height: "100%",
    padding: "50px",
    overflowY: "scroll" as "scroll",
    scrollbarColor: "#fff #fff",
    scrollbarWidth: "thin" as "thin",
    "& ::-webkit-scrollbar-button": {
      display: "none"
    },
  },
  profileHPHeading: {
    width: "100%",
    caretColor: "transparent",
    fontSize: "28px",
    fontFamily: "SF Pro Text Bold",
    color: "#292929",
  },
  headerHPContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  orderHeading: {
    display: "flex",
    alignItems: "center",
    marginBottom: "40px",
    justifyContent: "flex-start",
    gap: "25px",
  },
  calendarHPBackBtn: {
    display: "flex",
    alignItems: "center",
    fontSize: "22px",
    padding: "13px 9px 13px 17px",
    justifyContent: "center",
    borderRadius: "8px",
    border: "1px solid #E9E9E9",
  },
  stepper: {
    "& .MuiStep-horizontal": {
      paddingTop: "0px",
      paddingBottom: "0px"
    },
    "& .MuiStepper-root": {
      padding: "0px !important"
    }
  },
  inputNameFields: {
    width: "100%",
    fontSize: "14px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      border: "none"
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      border: "none",
      "&::placeholder": {
        opacity: 1,
        color: "#9D9D9D !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff !important"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#fff !important"
    },

    fontFamily: "SF Pro Text Regular"
  },
  inputCityFields: {
    width: "100%",
    fontSize: "14px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      border: "none"
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      border: "none",
      "&::placeholder": {
        opacity: 1,
        color: "#9D9D9D !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
      borderLeft: "1px solid #DBDBDB",
      borderBottomLeftRadius: "0px",
      borderTopLeftRadius: "0px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff !important"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#fff !important"
    },

    fontFamily: "SF Pro Text Regular"
  },
  inputContainer: {
    display: "flex",
    background: "#EBF3FC",
    alignItems: "center" as "center",
    alignContent: "center" as "center",
    padding: "15px",
    marginBottom: "30px"
  },
  homeCureHeading: {
    fontSize: "28px",
    fontFamily: "SF Pro Text Bold",
    color: "#014866",
    marginRight: "10px",
    '@media (max-width: 600px)': {
      display: "none"
    },
    caretColor: "transparent"
  },
  seperationLine: {
    borderBottom: "6px solid #02A405",
    width: "176px",
    transform: "rotate(90deg)",
    borderBottomRightRadius: "3px",
    borderBottomLeftRadius: "3px",
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    marginRight: "5px",
    '@media (max-width: 600px)': {
      display: "none"
    }
  },
  stepConnector: {
    top: "12px",
    left: "calc(-94% + 20px)",
    right: "calc(88% + 20px)",
    '@media (max-width: 920px)': {
      left: "calc(-85% + 20px) !important"
    },
    '@media (max-width: 530px)': {
      left: "calc(-80% + 20px) !important",
      right: "calc(75% + 20px) !important",
    }
  },
  stepLabel: {
    display: "flex",
    alignItems: "flex-start",
  },
  stepperClasses: {
    padding: "0 !important",
    "& .MuiStepConnector-alternativeLabel": {
      top: "12px",
      left: "calc(-92% + 20px)",
      right: "calc(92% + 20px)",
      position: "absolute",
    },
    "& .MuiStepLabel-root": {
      display: "flex",
      alignItems: "flex-start" as "flex-start"
    },
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      textAlign: "left" as "left",
      fontFamily: "SF Pro Text Medium",
      fontSize: "14px",
      caretColor: "transparent"
    }
  },
  homecureLabel: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "16px",
    color: "#292929",
    caretColor: "transparent",
    marginBottom: "20px"
  },
  homecureVitalLabel: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "16px",
    marginTop: "15px",
    color: "#292929",
  },
  homecureBoldVitalLabel: {
    fontFamily: "SF Pro Text Semibold",
    fontSize: "16px",
    marginTop: "15px",
    color: "#292929",
  },
  menuItem: {
    "& .MuiMenuItem-root": {
      fontFamily: "SF Pro Text Regular",
      caretColor: "transparent !imporatnt",
    },
    "& .MuiListItem-button:hover": {
      caretColor: "transparent !imporatnt",
    }
  },
  inputTitleFields: {
    width: "50%",
    fontSize: "14px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      border: "none",
      caretColor: "transparent"
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      border: "none",
      "&::placeholder": {
        opacity: 1,
        color: "#9D9D9D !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },

    fontFamily: "SF Pro Text Regular"
  },
  inputDurationFields: {
    width: "100%",
    fontSize: "14px",
    borderRadius: "8px",
    marginTop: "5px",
    cursor:"pointer",
    caretColor:"transparent",
    "& .MuiInputBase-root": {
      color: "#014866",
      caretColor:"transparent",
      cursor:"pointer",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "transparent !important",
      border: "none"
    },
    "& input": {
      fontSize: "14px",
      caretColor:"transparent",
      color: "#014866",
      cursor:"pointer",
      fontFamily: "SF Pro Text Regular",
      border: "none",
      textTransform: "capitalize" as "capitalize",
      "&::placeholder": {
        opacity: 1,
        cursor:"pointer",
        caretColor:"transparent",
        color: "#9D9D9D !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent !important"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      height: "30px",
      borderColor: "transparent !important",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "transparent !important",
    },

    fontFamily: "SF Pro Text Regular"
  },
  inputTitleNameFields: {
    width: "100%",
    fontSize: "14px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      border: "none"
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      border: "none",
      "&::placeholder": {
        opacity: 1,
        color: "#9D9D9D !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderTopRighttRadius: "8px",
      borderBottomRightRadius: "8px",
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "0px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },

    fontFamily: "SF Pro Text Regular"
  },
  inputDegreeNameFields: {
    width: "100%",
    borderRadius: "8px",
    fontSize: "14px",
    cursor:"pointer",
    "& .MuiInputBase-root": {
      color: "#292929",
      cursor:"pointer",
      fontFamily: "SF Pro Text Regular",
      fontSize: "14px",
      border: "none",
      background: "white !important",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& input": {
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      color: "#292929",
      cursor:"pointer",
      caterColor:"transparent",
      border: "none",
      "&::placeholder": {
        caterColor:"transparent",
        opacity: 1,
        fontSize: "14px",
        color: "#9D9D9D !important",
        fontFamily: "SF Pro Text Regular",
      }
    },
    caterColor:"transparent",
    fontFamily: "SF Pro Text Regular"
  },
  degreeNameFields: {
    fontSize: "14px",
    width: "152px",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiInputBase-root": {
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      fontSize: "14px",
      border: "none",
      background: "white !important",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    "& input": {
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      color: "#292929",
      border: "none",
      "&::placeholder": {
        color: "#9D9D9D !important",
        opacity: 1,
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    fontFamily: "SF Pro Text Regular"
  },
  inputTimeFields: {
    width: "100%",
    borderRadius: "8px",
    fontSize: "14px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      border: "none",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      border: "none",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
        color: "#9D9D9D !important",
      }
    },
    fontFamily: "SF Pro Text Regular"
  },
  containerSpace: {
    marginBottom: "30px"
  },
  checkboxWhite: {
    color: "#fff !important",
    marginLeft: "2px !important",
  },
  checkbox: {
    color: "#fff !important",
    padding: "9px 15px 9px 0px"
  },
  consultType: {
    width: "100%",
    "& .MuiTypography-body1":
    {
      fontFamily: "SF Pro Text Regular",
      fontSize: "14px",
      color: "#292929",
      caretColor: "transparent"
    }
  },
  declaredBy: {
    width: "100%",
    marginTop: "5px",
    "& .MuiTypography-body1":
    {
      fontFamily: "SF Pro Text Regular",
      fontSize: "12px",
      color: "#292929"
    }
  },
  nextBtn: {
    width: "120px",
    height: "52px",
    textTransform: 'capitalize' as 'capitalize',
    fontSize: '18px',
    borderRadius: '8px',
    fontFamily: 'SF Pro Text Bold',
    backgroundColor: '#014866',
    color: '#fff',
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
  },
  submitBtn: {
    width: "200px",
    height: "56px",
    textTransform: 'capitalize' as 'capitalize',
    fontSize: '18px',
    borderRadius: '8px',
    fontFamily: 'SF Pro Text Bold',
    backgroundColor: '#014866',
    color: '#fff',
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
  },
  vitalContainer: {
    width: "100%",
    background: "#f1f0f0",
    minHeight: "357px",
    borderRadius: "8px",
    padding: "20px"
  },
  chiefContainer: {
    width: "100%",
    background: "#f1f0f0",
    minHeight: "52px",
    borderRadius: "8px",
    padding: "5px 5px"
  },
  detailContainer: {
    width: "100%",
    background: "#f1f0f0",
    minHeight: "246px",
    borderRadius: "8px",
    padding: "20px"
  },
  signatureContainer: {
    width: "100%",
    background: "#f1f0f0",
    minHeight: "121px",
    borderRadius: "8px",
    padding: "20px"
  },
  rightContainer: {
    borderRight: "1px solid #d7d5d5",
    paddingRight: "5%",
    '@media (max-width: 600px)': {
      border: "none",
      paddingRight: "0%",
    }
  },
  leftContainer: {
    paddingLeft: "5%",
    '@media (max-width: 600px)': {
      paddingLeft: "0px"
    }
  },
  inputVitalFields: {
    width: "100%",
    fontSize: "14px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "transparent !important",
      border: "none"
    },
    "& .MuiFilledInput-input": {
      padding: "27px 0px 10px 0px !important"
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      border: "none",
      "&::placeholder": {
        opacity: 1,
        color: "#9D9D9D !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    fontFamily: "SF Pro Text Regular"
  },
  inputChiefFields: {
    width: "100%",
    fontSize: "14px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "transparent !important",
      border: "none"
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      border: "none",
      "&::placeholder": {
        opacity: 1,
        color: "#9D9D9D !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "transparent !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important"
    },
    fontFamily: "SF Pro Text Regular",
    "& .MuiFilledInput-underline::before": {
      borderBottom: "2px solid transparent"
    },
    "& .MuiFilledInput-underline::after": {
      borderBottom: "2px solid transparent"
    }
  },
  homecureDetailsLabel: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "14px",
    marginTop: "15px",
    color: "#808080",
  },
  inputDetailsFields: {
    width: "100%",
    fontSize: "12px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "12px",
      fontFamily: "SF Pro Text Regular",
      background: "transparent !important",
      border: "none"
    },
    "& .MuiFilledInput-input": {
      padding: "27px 0px 10px 0px !important"
    },
    "& input": {
      fontSize: "12px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      border: "none",
      "&::placeholder": {
        opacity: 1,
        color: "#292929 !important",
        fontSize: "12px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    fontFamily: "SF Pro Text Regular"
  },
  message: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "11px",
    color: "#808080",
    margin: "15px auto 10px"
  },
  diagnosisMessage: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "14px",
    color: "#292929",
    margin: "15px auto 10px"
  },
  hpRegisterInputField: {
    width: "100%",
    fontSize: "14px",
    caretColor: "transparent",
    backgroundColor: "#f1f0f0",
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    },
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-input": {
      cursor: "pointer"
    },
    marginBottom: "10px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      cursor: "pointer"
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      caretColor: "transparent",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        color: "#808080 !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
        caretColor: "transparent"
      }
    }
  },
  hpDownArrowIcon: {
    cursor: "pointer",
    width: "18px",
    height: "15px",
    marginRight: "6px",
    objectFit: "cover" as "cover"
  },
  hpUpArrowIcon: {
    width: "18px",
    height: "15px",
    cursor: "pointer",
    objectFit: "cover" as "cover"
  },
  hpSelectFieldDropdown: {
    width: "100%",
    borderRadius: "8px",
    backgroundColor: "#f1f0f0",
    marginTop: "10px",
  },
  hpDropdownContent: {
    padding: "10px 20px"
  },
  dropdownText: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 3px 15px 0px",
    color: "#808080",
    cursor: "pointer",
    borderBottom: "1px solid #ccc",
    transition: "background-color 0.3s ease",
    fontSize: "14px",
    caretColor: "transparent",
    fontFamily: "SF Pro Text Regular"
  },
  hpSelectFieldSize: {
    scrollbarColor: "#d9d5d5 #fff",
    scrollbarWidth: "thin" as "thin",
    overflowY: "scroll" as "scroll",
    "& ::-webkit-scrollbar-button": {
      display: "none"
    },
    maxHeight: "158px",
    minHeight: "158px"
  },
  hpSelectOption: {
    padding: "5px 10px 5px 0px",
    cursor: "pointer",
    borderBottom: "1px solid #ccc",
    width: "70%",
    transition: "background-color 0.3s ease",
    fontFamily: "Sf Pro Text Medium",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    color: "#292929"
  },
  subBtnDurationContainer: {
    alignItems: "center" as "center",
    display: "flex",
    border: "1px solid #014866",
    padding: "3px",
    height: "8px",
    marginRight: "10px"
  },
  subBtnContainer: {
    alignItems: "center" as "center",
    display: "flex",
    border: "1px solid #014866",
    padding: "3px",
    height: "8px",
    marginTop: "12px",
    marginRight: "10px"
  },
  addDurationBtnContainer: {
    height: "8px",
    border: "1px solid #014866",
    padding: "3px",
    marginLeft: "10px",
  },
  addBtnContainer: {
    height: "8px",
    marginLeft: "10px",
    border: "1px solid #014866",
    padding: "3px",
    marginTop: "12px"
  },
  stepperContainer: {
    width: '100%', margin: "20px auto 45px"
  },
  diagnosisTitle: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "18px",
    color: "#292929",
    marginBottom: "20px"
  },
  diagnosisTest: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "18px",
    color: "#292929",
    marginBottom: "20px",
    marginTop: "20px"
  },
  medicinesTitle: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "18px",
    color: "#292929",
  },
  vitalBox: {
    display: "flex",
    marginRight: "20px"
  },
  presDetails: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "14px",
    color: "#292929",
    marginTop: "10px"
  },
  medicicneNameTitle: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "16px",
    color: "#808080",
    margin: "10px 0px 15px"
  },
  doseDurationTitle: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "16px",
    color: "#808080",
    marginTop: "10px"
  },
  tabletText: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "16px",
    color: "#292929",
    margin: "10px 0px 10px"
  },
  doseTimeDurationTitle: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "16px",
    color: "#808080",
    marginTop: "14px",
    marginBottom: "5px"
  },
  timeDurationText: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "14px",
    color: "#292929",
    marginTop: "10px"
  },
  addBtn: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "14px",
    color: "#014866",
    textAlign: "right" as "right",
    marginTop: "15px",
    cursor: "pointer"
  },
  addMoreContainer: {
    margin: "20px 0px",
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular",
    color: "#292929"
  },
  signatureText: {
    margin: "25px 0px",
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    color: "#292929",
    display: "flex",
    justifyContent: "center"
  },
  inputErrors: {
    fontSize: "12px",
    fontFamily: "SF Pro Text Regular",
    color: "#FF0000",
    caretColor: "transparent",
    marginLeft: "5px"
  },
  chip: {
    "& .MuiChip-deleteIcon": {
      color: "#000000 !important"
    },
    "&.MuiChip-label": {
      paddingLeft: "8px !important",
      paddingRight: "8px !important",
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: "ellipsis"
    },
    "& .MuiChip-sizeSmall": {
      height: "35px"
    },
    borderRadius: "6px",
    margin: "1px 4px",
    alignItems: "center",
    padding: "7px 8px",
    fontSize: "14px",
    backgroundColor: "#e2ecf0",
    color: "#014866",
    fontFamily: "SF Pro Text Regular",
  },
  container: {
    display: "block",
    maxWidth: "550px",
    height: "auto",
    width: "500px",
    textAlign: "left" as "left",
    borderRadius: "5px",
  },
  headingText: {
    width: "100%",
    fontFamily: "SF Pro Text Regular",
    height: "8px",
    marginTop: "15px",
    fontSize: "28px",
    fontWeight: 700,
    color: "#292929",
    caretColor: "white"
  },
  paragraphText: {
    width: "100%",
    color: "#292929",
    fontSize: "16px",
    caretColor: "white",
    fontFamily: "SF Pro Text Regular",
    margin: "30px 0px",
    height: "8px",
  },
  selectMenu: {
    "& .MuiPopover-root": {
      top: "100px !inportant"
    },
    "& .MuiMenuItem-root": {
      fontFamily: "SF Pro Text Regular",
      caretColor: "transparent !imporatnt",
    },
    "& .MuiListItem-button:hover": {
      caretColor: "transparent !imporatnt",
    }
  },
  selectItem: {
    padding: '10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  selectDay: {
    padding: '10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  inputRepeatFields: {
    fontSize: "14px",
    cursor:"pointer",
    width: "40px",
    color:"#3780E0",
    borderBottom: "1px solid #3780E0",
    height: "20px",
    textAlign: "center" as "center",
    fontFamily: "SF Pro Regular",
    marginTop: "10px"
  },
}

export default withStyles(styles)(HealthcarePersonnelPrescription);
// Customizable Area End